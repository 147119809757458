import { makeObservable, observable } from 'mobx';

import { ClientConfig } from 'msg-swagger';

import { FirewallInfo, StatisticsClient } from 'swagger/generated';

import { AppStore } from './AppStore';
import { BaseStore } from './BaseStore';

export class StatisticsStore extends BaseStore {

    statisticsClient: StatisticsClient | undefined;
    firewallInfos: FirewallInfo[] | undefined;

    constructor(appStore: AppStore) {
        super(appStore);
        makeObservable(this, {
            firewallInfos: observable,
        });
    }

    init() { }

    async load(config: ClientConfig) {
        this.statisticsClient = new StatisticsClient(config);

        this.firewallInfos = await this.statisticsClient.index();
    }
}