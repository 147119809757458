import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExpand } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

import { ExtendedPointDto } from 'models/ExtendedPointDto';
import { appStore } from 'stores/AppStore';
import { time } from 'utils/Utils';
import { ColumnsType } from 'antd/lib/table';


interface Props {
    points: ExtendedPointDto[] | undefined;
    width?: number;
}

export const PointDetails: React.FC<Props> = ({
    points,
}) => {
    const { debugStore, uiStore } = appStore;

    if (!points || points.length === 0)
        return null;

    const currentIndex = Math.max(points.findIndex(p => p.id === uiStore.selectedPoint?.id), 0);

    const columns: ColumnsType<any> = [];

    columns.push({
            title: '',
            dataIndex: 'title',
            key: 0
    })

    const getTitle = (point: ExtendedPointDto, onClick?: () => void) => {
        return (
            <div onClick={onClick} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon
                    onClick={(evt) => { evt.stopPropagation(); uiStore.setSelectedPointAndShowExtent(point); }}
                    icon={faExpand}
                    style={{ marginRight: '8px' }} />
                {point.trackPriority}
                <div className="track-rect" style={{ marginLeft: '8px', marginRight: '8px', backgroundColor: point.trackColor }} />
                {'Point ' + point.index}
                {point.isLongRange
                    ? <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '8px' }} color="blue" />
                    : (point.isOutlier ? <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '8px' }} color="red" /> : "" )
                }
            </div>
        )
    }

    if (currentIndex - 3 > -1)
        columns.push({
            title: getTitle(points[currentIndex - 3], () => uiStore.setSelectedPoint(points[currentIndex - 3])),
            dataIndex: 'previous-3',
            key: currentIndex - 3
        })

    if (currentIndex - 2 > -1)
        columns.push({
            title: getTitle(points[currentIndex - 2], () => uiStore.setSelectedPoint(points[currentIndex - 2])),
            dataIndex: 'previous-2',
            key: currentIndex - 2
        })

    if (currentIndex - 1 > -1)
        columns.push({
            title: getTitle(points[currentIndex - 1], () => uiStore.setSelectedPoint(points[currentIndex - 1])),
            dataIndex: 'previous',
            key: currentIndex - 1
        })

    if (currentIndex > -1)
        columns.push({
            title: getTitle(points[currentIndex]),
            dataIndex: 'current',
            key: currentIndex,
            className: 'highlighted-row'
        })

    if (currentIndex + 1 < points.length)
        columns.push({
            title: getTitle(points[currentIndex + 1], () => uiStore.setSelectedPoint(points[currentIndex + 1])),
            dataIndex: 'next',
            key: currentIndex + 1
        })

    if (currentIndex + 2 < points.length)
        columns.push({
            title: getTitle(points[currentIndex + 2], () => uiStore.setSelectedPoint(points[currentIndex + 2])),
            dataIndex: 'next-2',
            key: currentIndex + 2
        })

    if (currentIndex + 3 < points.length)
        columns.push({
            title: getTitle(points[currentIndex + 3], () => uiStore.setSelectedPoint(points[currentIndex + 3])),
            dataIndex: 'next-3',
            key: currentIndex + 3
        })

    const dataSource: any = [];

    let timeRow: any = {};
    timeRow.title = 'Time';
    columns.forEach((column: any) => {
        if (column.dataIndex === 'title')
            return;
        const point = points[column.key];
        timeRow[column.dataIndex] = point ? time(point.time) : undefined;
    });
    dataSource.push(timeRow);


    debugStore.logMessages.forEach((title, index) => {
        const obj: any = {};

        const isBooleanRow = columns.findIndex(column => points[column.key as number]?.log?.[index]?.toLowerCase() === "true") > -1;
        let foundValue = false;

        obj.title = title;
        columns.forEach((column: any) => {
            if (column.dataIndex === 'title')
                return;
            const point = points[column.key];

            if (!isBooleanRow) {
                obj[column.dataIndex] = point.log?.[index];

                if (point.log?.[index] !== undefined && point.log?.[index] != null)
                    foundValue = true;
            }
            else {
                if (point.log?.[index])
                    obj[column.dataIndex] = <FontAwesomeIcon icon={faCheck} />;
                else
                    obj[column.dataIndex] = <FontAwesomeIcon icon={faTimes} />;
            }

        })

        if (foundValue || isBooleanRow)
            dataSource.push(obj);
    });

    if (columns.length < 8) {

        if (currentIndex - 3 < 0)
            columns.splice(1, 0, {
                dataIndex: 'previous-3',
                key: currentIndex - 3
            })

        if (currentIndex - 2 < 0)
            columns.splice(2, 0, {
                dataIndex: 'previous-2',
                key: currentIndex - 2
            })

        if (currentIndex - 1 < 0)
            columns.splice(3, 0, {
                dataIndex: 'previous',
                key: currentIndex - 1
            })

        if (currentIndex + 1 >= points.length)
            columns.push({
                dataIndex: 'next',
                key: currentIndex + 1
            })

        if (currentIndex + 2 >= points.length)
            columns.push({
                dataIndex: 'next-2',
                key: currentIndex + 2
            })

        if (currentIndex + 3 >= points.length)
            columns.push({
                dataIndex: 'next-3',
                key: currentIndex + 3
            })
    }


    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            showSorterTooltip={false}
            pagination={false}
            size="small"
            className="point-details" 
        >
        </Table>
    );
};

export default observer(PointDetails);