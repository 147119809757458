import { action, makeObservable, observable, reaction } from 'mobx';

import { Extent } from 'ol/extent';

import HasViewFitExtensions from 'msg-ui-map-react/dist/extensions/HasViewFitExtensions';
import { ClientConfig } from 'msg-swagger';

import { AppStore } from './AppStore';
import { BaseStore } from './BaseStore';
import { Map } from 'map/map'
import { ExtendedPointDto } from 'models/ExtendedPointDto';
import moment from 'moment';


export enum Tab { Home, Vessels, Debug, Override };
export enum DebugTab { Summary, Points };
export enum BottomPanelTab { Tracks, PointDetails };
export enum ShowTrackOptions { ShowA, ShowB, ShowBoth };
export enum ShowTrackOptionsOutliers { No, Yes };
export enum TimeFilterMode { None, Before, After, Between };


export const defaultShowOptionPoints = ShowTrackOptions.ShowBoth;
export const defaultShowOptionOutliers = ShowTrackOptionsOutliers.Yes;


export class UiStore extends BaseStore {

    zoomLevel: number | undefined;
    setZoomLevel(zoomLevel: number | undefined) {
        this.zoomLevel = zoomLevel;
    }

    currentTab: Tab = Tab.Debug;
    setCurrentTab(tab: Tab) {
        this.currentTab = tab;
    }

    currentDebugTab: DebugTab = DebugTab.Points;
    setCurrentDebugTab(debugTab: DebugTab) {
        this.currentDebugTab = debugTab;
    }

    currentMapLowerPanelTab: BottomPanelTab = BottomPanelTab.Tracks;
    setCurrentMapLowerPanelTab(bottomPanelTab: BottomPanelTab) {
        this.currentMapLowerPanelTab = bottomPanelTab;
    }

    selectedTrack: string | undefined;
    setSelectedTrack(selectedTrack: string | undefined) {
        this.selectedTrack = selectedTrack;
    }

    selectedPoint: ExtendedPointDto | undefined;
    setSelectedPoint(selectedPoint: ExtendedPointDto | undefined) {
        this.selectedPoint = selectedPoint;
    }
    setSelectedPointAndShowExtent(point: ExtendedPointDto) {
        this.selectedPoint = point;

        const extent = this.root.debugStore.getPointExtent(point);

        if (extent) {
            this.fitExtent(extent);
        }
    }

    hoveredTrackId: string | undefined;
    setHoveredTrackId(hoveredTrackId: string | undefined) {
        this.hoveredTrackId = hoveredTrackId;
    }

    currentHoverPoint: ExtendedPointDto | undefined;
    setCurrentHoverPoint(currentHoverPoint: ExtendedPointDto | undefined) {
        this.currentHoverPoint = currentHoverPoint;
    }

    hiddenTrackIds: string[] = []
    setHiddenTrackIds(hiddenTrackIds: string[]) {
        this.hiddenTrackIds = hiddenTrackIds;
    }

    showTrackExtent(trackId: string) {
        const extent = this.root.uiStore.olMap.tracksLayer.tracksVectorLayer.getExtentTrack(trackId);
        if (extent) {
           this.fitExtent(extent);
        }
    }

    toggleHiddenTrackId(hiddenTrackId: string) {
        if (this.root.uiStore.hiddenTrackIds.indexOf(hiddenTrackId) === -1)
            this.root.uiStore.hiddenTrackIds = this.root.uiStore.hiddenTrackIds.concat(hiddenTrackId);
        else
            this.root.uiStore.hiddenTrackIds = this.root.uiStore.hiddenTrackIds.filter(id => hiddenTrackId !== id);
    }

    toggleMmsi() {
        const allIds = this.root.debugStore.trackDtos!.map(ti => ti.id);

        if (this.root.uiStore.hiddenTrackIds.length === allIds.length)
            this.root.uiStore.hiddenTrackIds = []
        else
            this.root.uiStore.hiddenTrackIds = allIds;
    }

    /* begin section: Debug : RightPanel */
    showOptionPoints: ShowTrackOptions = defaultShowOptionPoints;
    setShowOptionPoints(showOptionPoints: ShowTrackOptions) {
        this.showOptionPoints = showOptionPoints;
    }

    showOptionOutliers: ShowTrackOptionsOutliers = defaultShowOptionOutliers;
    setShowOptionOutliers(showOptionOutliers: ShowTrackOptionsOutliers) {
        this.showOptionOutliers = showOptionOutliers;
    }

    bottomPanelHeight: number | undefined = undefined;
    setBottomPanelHeight(bottomPanelHeight: number) {
        this.bottomPanelHeight = bottomPanelHeight;
    }
    /* end section: Debug : RightPanel */

    /* begin section: Debug => TrackFiltersModal */
    getStoredFilterStartTime() {
        const s = localStorage.getItem('filter_startTime');
        return s ? moment(s) : moment();
    }

    getStoredFilterEndTime() {
        const s = localStorage.getItem('filter_endTime');
        return s ? moment(s) : moment();
    }

    getStoredDataStartTime() {
        const s = localStorage.getItem('data_startTime');
        return s ? moment(s) : moment();
    }

    getStoredDataEndTime() {
        const s = localStorage.getItem('data_endTime');
        return s ? moment(s) : moment();
    }

    setDataTimeDefaults(overwrite: boolean = false) {
        if (overwrite || this.dataStartTime == null) {
            this.setDataStartTime(moment.utc().add(-2, 'd'));
        }
        if (overwrite || this.dataEndTime == null) {
            this.setDataEndTime(moment.utc());
        }
    }

    dataStartTime: moment.Moment | null = moment.utc().add(-2, 'd');
    setDataStartTime(startTime: moment.Moment | null) {
        this.dataStartTime = startTime;
        if (startTime) {

            localStorage.setItem('data_startTime', startTime?.toISOString());
        }
    }

    dataEndTime: moment.Moment | null = moment.utc();
    setDataEndTime(endTime: moment.Moment | null) {
        this.dataEndTime = endTime;
        if (endTime) {

            localStorage.setItem('data_endTime', endTime?.toISOString());
        }
    }


    filterStartTime: moment.Moment | null = null;
    setFilterStartTime(startTime: moment.Moment | null) {
        this.filterStartTime = startTime;
        if (startTime) {

            localStorage.setItem('filter_startTime', startTime?.toISOString());
        }
    }

    filterEndTime: moment.Moment | null = null;
    setFilterEndTime(endTime: moment.Moment | null) {
        this.filterEndTime = endTime;
        if (endTime) {

            localStorage.setItem('filter_endTime', endTime?.toISOString());
        }
    }
    /* end section: Debug => TrackFiltersModal */

    olMap: Map;

    constructor(appStore: AppStore) {
        super(appStore);

        makeObservable(this, {
            zoomLevel: observable,
            currentTab: observable,
            setCurrentTab: action.bound,
            currentDebugTab: observable,
            setCurrentDebugTab: action.bound,
            currentMapLowerPanelTab: observable,
            setCurrentMapLowerPanelTab: action.bound,
            selectedTrack: observable,
            setSelectedTrack: action.bound,
            selectedPoint: observable,
            setSelectedPoint: action.bound,
            setSelectedPointAndShowExtent: action.bound,
            hoveredTrackId: observable,
            setHoveredTrackId: action.bound,
            currentHoverPoint: observable,
            setCurrentHoverPoint: action.bound,
            hiddenTrackIds: observable,
            toggleHiddenTrackId: action.bound,
            toggleMmsi: action.bound,
            setHiddenTrackIds: action.bound,
            showOptionPoints: observable,
            setShowOptionPoints: action.bound,
            showOptionOutliers: observable,
            setShowOptionOutliers: action.bound,
            bottomPanelHeight: observable,
            setBottomPanelHeight: action.bound,

            dataStartTime: observable,
            setDataStartTime: action.bound,
            dataEndTime: observable,
            setDataEndTime: action.bound,

            filterStartTime: observable,
            setFilterStartTime: action.bound,
            filterEndTime: observable,
            setFilterEndTime: action.bound
        });
    }

    init(): void {
        reaction(() => this.hiddenTrackIds, ((newValue: string[], oldValue: string[]) => {
            if (this.selectedTrack && oldValue.indexOf(this.selectedTrack) > -1 && newValue.indexOf(this.selectedTrack) === -1)
                this.selectedTrack = undefined;
        }));

        reaction(() => this.olMap.tracksLayer.tracksVectorLayer.loaded, (newValue: boolean, prevValue: boolean) => {
            if (prevValue === false && newValue === true) {
                const extents = this.root.debugStore.trackDtos!.map(ti =>
                    this.root.uiStore.olMap.tracksLayer.tracksVectorLayer.getExtentTrack(ti.id)).filter(extent => !!extent) as Extent[];

                if (extents?.length > 0)
                    this.fitExtent(extents);
            }
        });
    }

    fitExtent(extent: Extent | Extent[], force: boolean = false, maxZoom = 18) {
        if (extent.length > 0 && typeof extent[0] === 'number')
            new HasViewFitExtensions(this.olMap.getView()).fitExtent(extent as Extent);
        else
            new HasViewFitExtensions(this.olMap.getView()).fitExtents(extent as Extent[]);
    }

    async load(config: ClientConfig) { }
}