import React from 'react';
import { createRoot } from 'react-dom/client';

import { SentryClient } from "msg-sentry-client-web";
import App from './ui/App';

import 'antd/dist/antd.css';
import 'style/Index.less';


const root = createRoot(document.getElementById('root')!);

SentryClient.start(user => {
    console.log({ user });
    root.render(
        <React.StrictMode>
                <App />
        </React.StrictMode>
    );
});