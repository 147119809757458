import { BaseFilter, PropertyType } from "msg-spaces-client-web/dist/v2.0";
import { BasePropertyConfig } from "msg-properties-client-web/dist/generated";

import { BooleanFilterBuilder } from 'ui/filter/builders/BooleanFilterBuilder';
import { IntFilterBuilder } from "ui/filter/builders/IntFilterBuilder";
import { StringFilterBuilder } from 'ui/filter/builders/StringFilterBuilder';


export class FilterBuilderFactory {
    static create(propertyConfig: BasePropertyConfig, filter?: BaseFilter) {
        switch ((propertyConfig as any).type) {
            case PropertyType.Int:
                return new IntFilterBuilder(propertyConfig, filter);
            case PropertyType.Boolean:
                return new BooleanFilterBuilder(propertyConfig, filter);
            case PropertyType.String:
                return new StringFilterBuilder(propertyConfig, filter);
            default:
                return undefined;
        }
    }
}