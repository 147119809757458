import { faChevronLeft, faChevronRight, faChevronsLeft, faChevronsRight, faMagnifyingGlass, faDownload, faExpand, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, DatePicker, Input, InputNumber, Select, Switch, Tabs, Upload } from 'antd';
import { observer } from 'mobx-react';
import MultiPoint from 'ol/geom/MultiPoint';
import { useState } from 'react';

import MapLowerPanelBase from 'msg-ui-map-react/dist/components/MapLowerPanel';

import { RcFile } from 'antd/lib/upload';
import { ExtendedPointDto } from 'models/ExtendedPointDto';
import moment from 'moment';
import { appStore } from 'stores/AppStore';
import { BottomPanelTab, ShowTrackOptionsOutliers } from 'stores/UiStore';
import * as Geo from 'utils/Geo';
import PointDetails from './PointDetails';
import Tracks, { ITrackDtoRow } from './Tracks';
import React from 'react';
import { reaction } from 'mobx';

export interface IPointRow extends ExtendedPointDto {
    key: string;
}

export const MapLowerPanel = ({ }) => {

    const [mmsi, setMmsi] = useState<number | undefined>(() => {

        // Get mmsi from route, usefull when debugging
        if (window.location.pathname.startsWith('/mmsi/')) {
            const routeMmsi = Number.parseInt(window.location.pathname.substring(6));
            if (Number.isInteger(routeMmsi) && routeMmsi > 0) {
                return routeMmsi;
            }
        }

        return undefined;
    }); 

    const [downloadType, setdownloadType] = useState<string>("FWR"); 

    const { debugStore, uiStore, websettings } = appStore;

    reaction(() => debugStore.loadedMmsi, () => debugStore.loadedMmsi !== undefined && setMmsi(debugStore.loadedMmsi));

    const onChange = (key: string) => {
        appStore.uiStore.setCurrentMapLowerPanelTab(Number.parseInt(key));
    };

    const downloadAnchor = React.useRef<HTMLAnchorElement | null>(null);

    const filteredPoints = debugStore.visibleFilteredPoints;
    const selectedPointIndex = filteredPoints.findIndex(p => p.index === uiStore.selectedPoint?.index);

    const setInputSelectedPoint = (index: number) => {
        uiStore.setSelectedPoint(filteredPoints.find(p => p.index === index));
    }

    const setPreviousLayerSelectedPoint = () => {
        if (selectedPointIndex >= 0) {
            const p = filteredPoints[selectedPointIndex].trackId;
            for (var l = selectedPointIndex; l >= 0; l--) {
                if (filteredPoints[l].trackId !== p) {
                    uiStore.setSelectedPoint(filteredPoints[l]);
                    return;
                }
            }
        }
        uiStore.setSelectedPoint(filteredPoints[0])
    }
    const setNextLayerSelectedPoint = () => {
        if (selectedPointIndex >= 0) {
            const p = filteredPoints[selectedPointIndex].trackId;
            for (var l = selectedPointIndex; l < filteredPoints.length; l++) {
                if (filteredPoints[l].trackId != p) {
                    uiStore.setSelectedPoint(filteredPoints[l]);
                    return;
                }
            }
        }
        uiStore.setSelectedPoint(filteredPoints[filteredPoints.length - 1])
    }

    const setPreviousSelectedPoint = () => {        
        uiStore.setSelectedPoint(filteredPoints[selectedPointIndex - 1]);
    }

    const setNextSelectedPoint = () => {
        uiStore.setSelectedPoint(filteredPoints[selectedPointIndex + 1]);
    }

    const getPresetRanges: any = () => {

        const stored =  {
            'stored': [uiStore.getStoredFilterStartTime(), uiStore.getStoredFilterEndTime()],
            'prev': [uiStore.getStoredFilterStartTime().subtract(uiStore.getStoredFilterEndTime().diff(uiStore.getStoredFilterStartTime(), 'minutes', true), 'm'), uiStore.getStoredFilterStartTime()],
            'next': [uiStore.getStoredFilterEndTime(), uiStore.getStoredFilterEndTime().add(uiStore.getStoredFilterEndTime().diff(uiStore.getStoredFilterStartTime(), 'minutes', true), 'm')],
        }

        let selected = uiStore.selectedPoint?.time;
        if (!selected || filteredPoints.length === 0) {
            return stored;
        }
        return {
            'before': [filteredPoints[0].time, selected],
            '6 hours before': [selected.clone().add(-6, 'h'), selected],
            '2 hours before': [selected.clone().add(-2, 'h'), selected],
            '1 hour before': [selected.clone().add(-1, 'h'), selected],
            '12 minutes': [selected.clone().add(-6, 'm'), selected.clone().add(6, 'm')],
            '30 minutes': [selected.clone().add(-15, 'm'), selected.clone().add(15, 'm')],
            '1 hour': [selected.clone().add(-.5, 'h'), selected.clone().add(.5, 'h')],
            '2 hours': [selected.clone().add(-1, 'h'), selected.clone().add(1, 'h')],
            '6 hours': [selected.clone().add(-3, 'h'), selected.clone().add(3, 'h')],
            '1 hour after': [selected, selected.clone().add(1, 'h')],
            '2 hours after': [selected, selected.clone().add(2, 'h')],
            '6 hours after': [selected, selected.clone().add(6, 'h')],
            'after': [selected, filteredPoints[filteredPoints.length - 1].time],
            ...stored
        }
    }

    const getDataPresetRanges: any = () => {
        return {
            'stored': [uiStore.getStoredDataStartTime(), uiStore.getStoredDataEndTime()],
            '12 hours ago': [moment.utc().add(-12, 'h'), moment.utc()],
            '1 day ago': [moment.utc().add(-1, 'd'), moment.utc()],
            '2 day ago': [moment.utc().add(-2, 'd'), moment.utc()],
            '3 day ago': [moment.utc().add(-3, 'd'), moment.utc()],
            '4 day ago': [moment.utc().add(-4, 'd'), moment.utc()],
            '5 day ago': [moment.utc().add(-5, 'd'), moment.utc()]
        }
    }

    const isDisabledDate = (date: moment.Moment) =>
    {
        return /*date.isBefore(moment.utc().add(-websettings.maxAgeDataStorage,'d')) || */date.isAfter(moment.utc());
    }

    const download = async (mmsi: number, downloadType: string) => {

        let fileResponse = await debugStore.download(mmsi, downloadType);
        if (fileResponse === undefined) {
            return; 
        }

        if (downloadAnchor.current) {
            const url = window.URL.createObjectURL(fileResponse.data);
            downloadAnchor.current.href = url;
            downloadAnchor.current.download = fileResponse.fileName ?? 'filenamenotset.' + downloadType;
            downloadAnchor.current.click();
            window.URL.revokeObjectURL(url);
        }
        else {

            console.log('downloadAnchor.current does not excist', fileResponse, downloadAnchor);
        }
    }

    const headerMapPanel = (
        <>
            <DatePicker.RangePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={isDisabledDate}
                ranges={getDataPresetRanges()}
                minuteStep={5}
                value={[uiStore.dataStartTime, uiStore.dataEndTime]}
                showTime onChange={(range) => {
                    if (range && range[0] && range[1]) {
                        uiStore.setDataStartTime(range[0]);
                        uiStore.setDataEndTime(range[1]);
                    }
                    else {
                        uiStore.setDataStartTime(null);
                        uiStore.setDataEndTime(null);
                    }
                }} />

            <Input allowClear style={{ width: 150 }} value={mmsi} onChange={(value) => {
                if (!value.target.value) {
                    setMmsi(undefined);
                }
                else {
                    const v = Number.parseInt(value.target.value)
                    if (!Number.isNaN(v) && v >= 0) {
                        setMmsi(v);
                    }
                }
                }} />
            <Button
                type={debugStore.isFileUpload  === false ? 'primary' : 'default'}
                disabled={!uiStore.dataStartTime || !uiStore.dataEndTime || mmsi === undefined}
                onClick={() => { mmsi !== undefined && debugStore.loadTracks(mmsi); }}>
                <FontAwesomeIcon icon={faMagnifyingGlass}></FontAwesomeIcon>
            </Button>



            <div style={{ display: 'inline-block' }}>
                <Upload
                    openFileDialogOnClick
                    beforeUpload={(rcFile: RcFile) => {
                        debugStore.uploadFile(mmsi, { data: rcFile, fileName: rcFile.name });
                        return false;
                    }}
                    fileList={[]}
                    defaultFileList={[]}
                >
                    <Button
                        type={debugStore.isFileUpload === true ? 'primary' : 'default'}
                    ><FontAwesomeIcon icon={faUpload} /></Button>
                </Upload>
            </div>

            <Select
                defaultValue="FWR"
                value={downloadType}
                onChange={(value: string) => setdownloadType(value)}
                style={{ marginLeft: 16 }}
                options={[{ value: 'FWR', label: 'FWR' }, { value: 'ORE', label: 'ORE' }]}
            />
            <Button
                disabled={!uiStore.dataStartTime || !uiStore.dataEndTime || mmsi === undefined}
                onClick={() => { mmsi !== undefined && download(mmsi, downloadType) }}>
                <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon>
            </Button>

            <div style={{ display: 'inline-block', float: 'right' }}>

                <Switch

                    checkedChildren="Outliers" unCheckedChildren="Outliers" defaultChecked
                    onChange={(checked: boolean) => {
                    if (checked) {
                        uiStore.setShowOptionOutliers(ShowTrackOptionsOutliers.Yes);
                    }
                    else {
                        uiStore.setShowOptionOutliers(ShowTrackOptionsOutliers.No);
                    }
                    }} />

                <DatePicker.RangePicker 
                    format="YYYY-MM-DD HH:mm"
                    ranges={getPresetRanges()}
                    minuteStep={5}
                    placeholder={["Filter from", "Filter to"] }
                    showTime onChange={(range) => {
                        if (range && range[0] && range[1]) {
                            uiStore.setFilterStartTime(range[0]);
                            uiStore.setFilterEndTime(range[1]);
                        }
                        else {
                            uiStore.setFilterStartTime(null);
                            uiStore.setFilterEndTime(null);
                        }
                    }} />



                <Button 
                    onClick={() => {
                        // Button has different action depending on lower panel tab.
                        // - Tracks: show extent of all visible points within all selected track
                        // - PointDetails: show extent of points visible in point details tab
                        if (uiStore.currentMapLowerPanelTab === BottomPanelTab.Tracks) {
                            const extent = debugStore.getCurrentExtent();
                            uiStore.fitExtent(extent);
                        }
                        else if (uiStore.currentMapLowerPanelTab === BottomPanelTab.PointDetails) {
                            const currentIndex = Math.max(filteredPoints.findIndex(p => p.id === uiStore.selectedPoint?.id), 0);
                            const isValidIndex = (index: number) => 0 <= index && index <= filteredPoints.length;

                            const points: ExtendedPointDto[] = [];
                            for (let i = currentIndex - 2; i <= currentIndex + 2; i++) {
                                if (isValidIndex(i))
                                    points.push(filteredPoints[i]);
                            }

                            const extent = new MultiPoint(points.map(p => Geo.degToMet([p.lon, p.lat]))).getExtent();
                            uiStore.fitExtent(extent);
                        }
                    }}
                    disabled={filteredPoints.length < 2}>
                    <FontAwesomeIcon icon={faExpand}></FontAwesomeIcon>
                </Button>

                <Button  onClick={() => setPreviousLayerSelectedPoint()}
                    disabled={selectedPointIndex <= 0}>
                    <FontAwesomeIcon icon={faChevronsLeft}></FontAwesomeIcon>
                </Button>

                <Button onClick={() => setPreviousSelectedPoint()}
                    disabled={selectedPointIndex <= 0}>
                    <FontAwesomeIcon icon={faChevronLeft}></FontAwesomeIcon>
                </Button>

                <InputNumber value={uiStore.selectedPoint?.index} controls={false} onPressEnter={(e) => setInputSelectedPoint(Number.parseInt((e.target as any).value))} />

                <Button onClick={() => setNextSelectedPoint()}
                    disabled={selectedPointIndex >= filteredPoints.length - 1}>
                    <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon>
                </Button>

                <Button onClick={() => setNextLayerSelectedPoint()}
                    disabled={selectedPointIndex >= filteredPoints.length - 1}>
                    <FontAwesomeIcon icon={faChevronsRight}></FontAwesomeIcon>
                </Button>
                <a ref={downloadAnchor} />
            </div>
        </>
    );

    const items = [{
        label: 'Tracks',
        key: BottomPanelTab.Tracks.toString(),
        children:
            <Tracks
                trackDtos={debugStore.trackDtos}
                mmsi={debugStore.loadedMmsi}
                onClick={(record: ITrackDtoRow) => uiStore.setSelectedTrack(record.id)}
            ></Tracks >
    },
    {
        label: 'Point Details',
        key: BottomPanelTab.PointDetails.toString(),
        children:
            <PointDetails points={filteredPoints} />
    }];

    return (<MapLowerPanelBase
        header=''
        headerMenu={headerMapPanel}
        headerStyle={{ width: '100%', display: 'flex' } }
        content={<Tabs
            defaultActiveKey={uiStore.currentMapLowerPanelTab.toString()}
            activeKey={uiStore.currentMapLowerPanelTab.toString()}
            onChange={onChange}
            items={items}
            style={{ overflow: 'auto' }}
            size="small" />
        }
        resizeProps={{ onResize: (e: React.SyntheticEvent, data: any) => uiStore.bottomPanelHeight = data.size.height }}
    />)
}

export default observer(MapLowerPanel);