import * as proj from 'ol/proj';
import * as ol_extent from 'ol/extent';
import { Extent } from 'ol/extent';
import { Coordinate } from 'ol/coordinate';


export function metToDeg<T extends Coordinate | Extent>(coords: T): T {
    if (coords.length === 2)
        return transformCoordinates(coords as Coordinate, "EPSG:3857", "EPSG:4326") as T;
    else if (coords.length === 4)
        return proj.transformExtent(coords as Extent, "EPSG:3857", "EPSG:4326") as T;
    else
        throw new Error();
}

export function degToMet<T extends Coordinate | Extent>(coords: T): T {
    if (coords.length === 2)
        return transformCoordinates(coords as Coordinate, "EPSG:4326", "EPSG:3857") as T;
    else if (coords.length === 4)
        return proj.transformExtent(coords as Extent, "EPSG:4326", "EPSG:3857") as T;
    else
        throw new Error();
}

export function transformCoordinates(coords: Coordinate, from: string, to: string): Coordinate {
    if (from === "EPSG:3857" && to === "EPSG:4326") {
        return [ 
            180 * coords[0] / EPSG3857_HS,
            360 * Math.atan(Math.exp(coords[1] / EPSG3857_R)) / Math.PI - 90
        ];
    } else if (to === "EPSG:3857" && from === "EPSG:4326") {
        return [
            coords[0] * EPSG3857_HS / 180,
            Math.log(Math.tan(Math.PI * (coords[1] + 90) / 360)) * EPSG3857_R
        ];
    } else {
        return proj.transform(coords, from, to);
    }
} 

export function transformExtent(coords: Extent, from: string, to: string): Extent {
    return proj.transformExtent(coords, from, to);
}

export function metToRad(coord: Coordinate): Coordinate {
    const deg = metToDeg(coord);
    return [degToRad(deg[0]), degToRad(deg[1])];
}

export function radToMet(coord: Coordinate): Coordinate {
    const deg: Coordinate = [radToDeg(coord[0]), radToDeg(coord[1])];
    return degToMet(deg);
}

export function extentToMet(extent: Extent) {
    return proj.transformExtent(extent, "EPSG:4326", "EPSG:3857");
}

export function metersToNM(meters: number) {
    return meters / 1852;
}

export function NMToMeters(NM: number) {
    return NM * 1852;
}

export function angle0To360(deg: number) {
    deg = deg % 360.0;
    return deg < 0.0 ? deg + 360.0 : deg;
}

export function angle180To180(deg: number) {
    deg = angle0To360(deg);
    return deg >= 180.0 ? deg - 360.0 : deg;
}

export function angle0To2PI(rad: number) {
    let n = rad / (Math.PI * 2);
    return (n - Math.floor(n)) * Math.PI * 2;
}

export function anglePIToPI(rad: number) {
    rad = angle0To2PI(rad);
    return rad >= Math.PI ? rad - Math.PI * 2 : rad;
}

export function degToRad(deg: number | null) {
    if (deg == null) return 0;
    return deg * Math.PI / 180;
}

export function radToDeg(rad: number) {
    return rad * 180 / Math.PI;
}

export function getPointResolution(resolution: number, point: Coordinate) {
    const p = metToDeg(point);
    const coslat = Math.cos(Math.abs(degToRad(p[1])));
    return resolution * coslat;
}

export function getZoomFromResolution(resolution: number) {
    return Math.log((40075008 / 256) / resolution) / Math.log(2);
}

export function getResolutionFromZoom(zoom: number) {
    return (40075008 / 256) / (Math.pow(2, zoom));
}

export function nearestZoomResolution(resolution: number): number {
    return getResolutionFromZoom(Math.round(getZoomFromResolution(resolution)));
}

export function distance(coord1: Coordinate, coord2: Coordinate = [0,0]) {
    const dx = coord1[0] - coord2[0];
    const dy = coord1[1] - coord2[1];
    return Math.sqrt(dx * dx + dy * dy);
}

export function getAngle(coord1: Coordinate, coord2: Coordinate) {
    const dx = coord2[0] - coord1[0];
    const dy = coord2[1] - coord1[1];
    return Math.atan2(dy, dx);
}
export function toUV(value: number, angle: number) {
    const a = degToRad(angle);
    return [Math.cos(a) * value, Math.sin(a) * value];
}

export function wrapCoordinateMet(coordinate1: Coordinate, coordinate2: Coordinate): Coordinate
{
    const lonDiff = coordinate2[0] - coordinate1[0];
    if (lonDiff > wmRange) {
        coordinate1[0] += wmRange * 2;
    } else if (lonDiff < -wmRange) {
        coordinate1[0] -=  wmRange * 2;
    }
    return coordinate1;
}

export function coordinateEqual(coord0: Coordinate, coord1: Coordinate) {
    if (!!coord0 !== !!coord1) return false;
    if (!coord0 && !coord1) return true;

    return coord0[0] === coord1[0] && coord0[1] == coord1[1];
}

export function normalizeCoordinate(coord: Coordinate, mercator = true): Coordinate {

    if (mercator) coord = metToDeg(coord);
    coord = [angle180To180(coord[0]), coord[1]];
    return mercator ? degToMet(coord) : coord;
}

export function normalizeExtent(extent: Extent, mercator = true): Extent {

    const center = normalizeCoordinate(ol_extent.getCenter(extent), mercator);
    const w = ol_extent.getWidth(extent);
    return [
        center[0] - w / 2, extent[1],
        center[0] + w / 2, extent[3]
    ];

}

export function lineIntersection(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number) {
    var ua, ub, denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
    if (denom == 0) {
        return null;
    }
    ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
    ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;
    if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
        return {
            x: x1 + ua * (x2 - x1),
            y: y1 + ua * (y2 - y1),
        };
    } else {
        return null;
    }
}

// Unwrap dateline crossing
export function unwrap(points: Array<Coordinate>, firstPoint: Coordinate = points[0], w: number = wmRange) {
    if (!points.length) return false;
    let add = 0;
    let last_x = firstPoint[0];
    let changed = false;
    points.forEach(point => {
        point[0] += add;
        const dx = point[0] - last_x;
        if (dx > w) {
            while (point[0] - last_x > w) {
                add -= w * 2;
                point[0] -= w * 2;
            }
            changed = true;
        } else if (dx < -w) {
            while (point[0] - last_x < -w) {
                add += w * 2;
                point[0] += w * 2;
            }
            changed = true;
        }
        last_x = point[0];
    });
    return changed;
}

// Split line at date line crossing
export function splitAtDateLine(points: Coordinate[], w: number = wmRange): Coordinate[][] {

    const results: Coordinate[][] = [[]];
    let prev = points[0];
    points.forEach(point => {
        // difference between x coords
        const dx = prev[0] - point[0];
        if (Math.abs(dx) > w) {
            // x distance from point to dateline
            const dxl = w - Math.abs(point[0]);
            // x distance between points over date line
            const dxol = w * 2 - Math.abs(dx);
            // y distance between points
            const dy = prev[1] - point[1];
            // y intersection
            const y = point[1] + dy * dxl / dxol;
            // add intermediate points and start a new line
            results[results.length - 1].push([dx > 0 ? w : -w, y]);
            results.push([[dx > 0 ? -w : w, y]]);
        }
        // Add point to result
        results[results.length - 1].push(point);
        // store previous
        prev = point;
    });
    return results;
}


const EPSG3857_R = 6378137;
const EPSG3857_HS = 6378137 * Math.PI;
export const earthRadius = 6371e3 / 1852;
export const wmRange = 20037508.342789244;

