import { reaction } from 'mobx';

import { Style } from 'ol/style';
import Feature, { FeatureLike } from 'ol/Feature';
import { Coordinate } from 'ol/coordinate';
import MultiPoint from 'ol/geom/MultiPoint';
import LineString from 'ol/geom/LineString';
import GeometryCollection from 'ol/geom/GeometryCollection';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import { PointDto } from 'swagger/generated';

import * as Geo from 'utils/Geo';
import { lineStyle, pointStyle, pointStyleColor } from 'map/styles/TrackStyle';
import { appStore } from 'stores/AppStore';
import { ShowTrackOptions, ShowTrackOptionsOutliers } from 'stores/UiStore';
import { ExtendedPointDto } from 'models/ExtendedPointDto';
import { ExtendedTrackDto } from '../../models/ExtendedTrackDto';


export class TrackLineString extends LineString {

    constructor(coordinates: Coordinate[], public points: ExtendedPointDto[], public color: pointStyleColor = pointStyleColor.normal) {
        // Add index as third value, this index can be used when a coordinate from this.getClosestPoint() is returned:
        // https://gis.stackexchange.com/questions/243114/openlayers-3-how-to-get-id-index-ol-geom-linestring-vertex-by-coordinates
        var coordinatesExtended = coordinates.map((coor, index) => [coor[0], coor[1], index]);
        super(coordinatesExtended, "XYM");
    }
}

export class TrackMultiPoint extends MultiPoint {

    constructor(coordinates: Coordinate[], public points: ExtendedPointDto[], public color: pointStyleColor = pointStyleColor.normal) {
        // Add index as third value, this index can be used when a coordinate from this.getClosestPoint() is returned:
        // https://gis.stackexchange.com/questions/243114/openlayers-3-how-to-get-id-index-ol-geom-linestring-vertex-by-coordinates
        var coordinatesExtended = coordinates.map((coor, index) => [coor[0], coor[1], index]);
        super(coordinatesExtended, "XYM");
    }
}

export class TrackFeature extends Feature {

    constructor(private track: ExtendedTrackDto) {
        super();

        if (track && track.id) {
            this.setId(track.id);
        }

        this.setGeometry(new GeometryCollection(this.getGeometries()));

        this.setStyle(this.styleFn_);

        reaction(() => appStore.uiStore.selectedPoint, () => {
            this.changed();
        })

        if (this.track && this.track.id) {
            reaction(() => appStore.uiStore.selectedTrack === this.track.id, (value: boolean) => {
                if (this.highlighted !== value) {
                    this.highlighted = value;
                    this.changed();
                }
            });
        }

        reaction(() => appStore.uiStore.showOptionPoints, () => {
            this.changed();
        });

        reaction(() => appStore.uiStore.showOptionOutliers, () => {
            this.changed();
        });

        reaction(() => appStore.uiStore.filterStartTime, () => {
            this.changed();
        })

        reaction(() => appStore.uiStore.filterEndTime, () => {
            this.changed();
        })
    }

    private highlighted: boolean;

    hitDetect(evt: MapBrowserEvent<UIEvent>): ExtendedPointDto | undefined {
        let nearestPoint: ExtendedPointDto | undefined;
        let foundMinDistance: number = Number.MAX_VALUE;

        const extent = appStore.uiStore.olMap.getView().calculateExtent();
        const resolution = appStore.uiStore.olMap.getView().getResolution();

        const geometries = this.getGeometries().reverse();
        for (let i = 0; i < geometries.length; i++) {

            // retrieve index from getClosestPoint in geometry, this index is now a double to indicate point on line 
            // between 2 points (e.g. 2,765 is closest point between points with index 2 and 3 and more closer to point with index 3)
            const index = Math.round(geometries[i].getClosestPoint(evt.coordinate)[2]);
            
            if (geometries[i].points[index]) {
                const point = geometries[i].points[index];
                const coord = Geo.degToMet([point!.lon, point!.lat]);

                const x = Math.round((coord[0] - extent[0]) / resolution!);
                const y = Math.round((extent[3] - coord[1]) / resolution!);

                const dx = x - evt.pixel[0];
                const dy = y - evt.pixel[1];

                const dist = Math.sqrt(dx * dx + dy * dy);

                if (dist < 25 && dist < foundMinDistance) {
                    foundMinDistance = dist;
                    nearestPoint = point;
                }
            }
        }

        return nearestPoint;
    }

    private get aPointsGeom() {
        const filteredPointsA = this.filter(this.track.pointsA);
        return new TrackLineString(this.toCoordinates(filteredPointsA), filteredPointsA);
    }

    private get bPointsGeom() {
        const filteredPointsB = this.filter(this.track.pointsB);
        return new TrackLineString(this.toCoordinates(filteredPointsB), filteredPointsB);
    }

    private get aOutliersGeom() {
        const filteredOutliersA = this.filter(this.track.outliersA);
        return new TrackMultiPoint(this.toCoordinates(filteredOutliersA), filteredOutliersA, pointStyleColor.outlier);
    }

    private get bOutliersGeom() {
        const filteredOutliersB = this.filter(this.track.outliersB);
        return new TrackMultiPoint(this.toCoordinates(filteredOutliersB), filteredOutliersB, pointStyleColor.outlier);
    }

    private get aLongRangeGeom() {
        const filteredLongrangeA = this.filter(this.track.longrangeA);
        return new TrackMultiPoint(this.toCoordinates(filteredLongrangeA), filteredLongrangeA, pointStyleColor.longrange);
    }

    private get bLongRangeGeom() {
        const filteredLongrangeB = this.filter(this.track.longrangeB);
        return new TrackMultiPoint(this.toCoordinates(filteredLongrangeB), filteredLongrangeB, pointStyleColor.longrange);
    }

    private getGeometries() {
        const geometries: (TrackLineString | TrackMultiPoint)[] = []

        switch (appStore.uiStore.showOptionPoints) {
            case ShowTrackOptions.ShowA:
                geometries.push(this.aPointsGeom);
                break;
            case ShowTrackOptions.ShowB:
                geometries.push(this.bPointsGeom);
                break
            case ShowTrackOptions.ShowBoth:
                geometries.push(this.aPointsGeom);
                geometries.push(this.bPointsGeom);
                break;
            default:
                geometries.push(this.aPointsGeom);
                break;
        }

        switch (appStore.uiStore.showOptionOutliers) {
            case ShowTrackOptionsOutliers.No:
                break;
            case ShowTrackOptionsOutliers.Yes:
                geometries.push(this.aLongRangeGeom);
                geometries.push(this.bLongRangeGeom);
                geometries.push(this.aOutliersGeom);
                geometries.push(this.bOutliersGeom);
                break;
            default:
                geometries.push(this.aLongRangeGeom);
                geometries.push(this.bLongRangeGeom);
                geometries.push(this.aOutliersGeom);
                geometries.push(this.bOutliersGeom);
                break;
        }

        return geometries;
    }

    private getStyles(resolution: number) {
        const styles: Style[] = [];
        const scale = this.getIconScale(resolution);
        const geometries = this.getGeometries();

        const selectedPoint = geometries
            .map(g => g.points.find(point => appStore.uiStore.selectedPoint?.id === point.id))
            .filter(f => f !== undefined)[0];

        geometries.forEach((geometry, index) => {
            if (geometry instanceof LineString) {
                lineStyle(geometry, styles, scale, this.track.color, this.highlighted, selectedPoint?.index, selectedPoint?.match);
            }

            if (geometry instanceof TrackMultiPoint) {
                pointStyle(geometry, styles, scale, geometry.color, selectedPoint?.index, selectedPoint?.match)
            }
        });

        return styles;
    }

    private styleFn_ = (feature: FeatureLike, resolution: number) => {
        return this.getStyles(resolution);
    }

    private toCoordinates(points: PointDto[]) {
        return points.map(point => Geo.degToMet([point.lon, point.lat]));
    }

    private filter(points: ExtendedPointDto[]) {
        return points.filter(p => appStore.uiStore.filterStartTime?.isBefore(p.time) ?? true)
            .filter(p => appStore.uiStore.filterEndTime?.isAfter(p.time) ?? true);
    }

    private getIconScale(resolution: number) {
        const zoom = Geo.getZoomFromResolution(resolution);
        return (Math.min(1.0, 0.1 * (Math.max(7, zoom) - 2)));
    }
};