import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Col, InputNumber, Select } from 'antd';

import { ComparingOptions, ComparingOptionStrings } from 'ui/filter/models/models';
import { IntFilterBuilder } from 'ui/filter/builders/IntFilterBuilder';
import { FilterComponent } from './FilterComponent';


interface Props {
    description?: string;
    filterBuilder: IntFilterBuilder;
    onChange: (filterBuilder: IntFilterBuilder) => void;
    onRemove?: ((acronym: string) => void);
}

export const IntFilterComponent: React.FC<Props> = ({ description, filterBuilder, onChange, onRemove }) => {

    const handleChange = (value: number | null) => {
        filterBuilder.setValue(value != null ? value : undefined);
        onChange(filterBuilder);
    }
    const handleChange2 = (value: number | null) => {
        filterBuilder.setValue2(value != null ? value : undefined);
        onChange(filterBuilder);
    }
    const handleChangeDropDown = (comparingOptions: ComparingOptions) => {
        filterBuilder.selectedComparingOption = comparingOptions;
        onChange(filterBuilder);
    }

    const hasSecondValue = filterBuilder.selectedComparingOption == "Between" || filterBuilder.selectedComparingOption == "Not between";
    const comparingOptions = ComparingOptionStrings.All.filter(option => option != ComparingOptionStrings.Any);

    const propertyConfig = filterBuilder.propertyConfig;

    return (
        <FilterComponent propertyConfig={propertyConfig} onRemove={onRemove} description={description}>
            <Col span={hasSecondValue ? 24 : 12}>
                <Select style={{ width: '100%' }} value={filterBuilder.selectedComparingOption} defaultValue={filterBuilder.selectedComparingOption}
                    onChange={value => handleChangeDropDown(value)}>
                    {comparingOptions?.map((option) => (
                        <Select.Option key={option} value={option}>{option}</Select.Option>
                    ))}
                </Select>
            </Col>
            <Col span={hasSecondValue ? 24 : 12}>
                <InputNumber
                    autoComplete="off"
                    value={filterBuilder.value}
                    defaultValue={filterBuilder.value}
                    min={propertyConfig.minValue !== undefined ? propertyConfig.minValue : Number.NEGATIVE_INFINITY}
                    max={propertyConfig.maxValue !== undefined ? propertyConfig.maxValue : Number.POSITIVE_INFINITY}
                    step={propertyConfig.resolution ? propertyConfig.resolution : 1}
                    onChange={handleChange}
                    style={{ width: '100%' }} />
            </Col>
            {hasSecondValue &&
                <Col span={24}>
                    <InputNumber
                        autoComplete="off"
                        value={filterBuilder.value2}
                        defaultValue={filterBuilder.value2}
                        min={propertyConfig.minValue !== undefined ? propertyConfig.minValue : Number.NEGATIVE_INFINITY}
                        max={propertyConfig.maxValue !== undefined ? propertyConfig.maxValue : Number.POSITIVE_INFINITY}
                        step={propertyConfig.resolution ? propertyConfig.resolution : 1}
                        onChange={handleChange2}
                        style={{ width: '100%' }} />
                </Col>
            }
        </FilterComponent>
    );
}

export default observer(IntFilterComponent);