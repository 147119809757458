import { BaseFilter, IBaseFilter, PropertyType } from "msg-spaces-client-web/dist/v2.0";
import { BasePropertyConfig } from "msg-properties-client-web/dist/generated";

import { ComparingOptions } from "ui/filter/models/models";


export abstract class FilterBuilder<T extends BaseFilter> {

    constructor(private _createFilter: { new(data: IBaseFilter): T }) { }

    abstract propertyConfig: BasePropertyConfig;

    abstract selectedComparingOption: ComparingOptions | undefined;

    abstract value: any;
    abstract setValue(value: any): void;

    abstract isValid(): boolean | undefined;

    abstract fromFilter(filter: BaseFilter): void;

    toFilter(): BaseFilter | undefined {
        return new this._createFilter({ acronym: this.acronym, type: this.propertyType });
    };

    get acronym() {
        return this.propertyConfig.acronym;
    }

    get propertyType(): PropertyType {
        return (this.propertyConfig as any).type;
    }
}