import React from 'react';
import { observer } from 'mobx-react';
import { Col, Row, Tooltip } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { BasePropertyConfig, PropertyType } from 'msg-properties-client-web/dist/generated';

import { addSpacesBeforeCapitals, capitalizeFirstLetter } from 'utils/Utils';


export interface FilterComponentProps {
    children: React.ReactNode;    
    description?: string;
    propertyConfig: BasePropertyConfig;    
    onRemove?: ((acronym: string) => void);
}

export const FilterComponent: React.FC<FilterComponentProps> = ({ children, description, propertyConfig, onRemove }) => {
    const [tipHovered, setTipHovered] = React.useState(false);

    return (
        <div style={{ width: (propertyConfig as any).type == PropertyType.Boolean ? '180px' : '300px', margin: '15px' }}>
            <Row className="property-filter-row">
                <Col span={24}>
                    <Row wrap={false} className="property-filter-item__label">
                        <Col flex="auto">
                            <Tooltip placement="topLeft" title={propertyConfig.description ?? ''}>
                                <Row onMouseEnter={() => setTipHovered(true)} onMouseLeave={() => setTipHovered(false)}>
                                    <Col>
                                        {description ? description : capitalizeFirstLetter(addSpacesBeforeCapitals(propertyConfig.acronym ?? ""))}
                                    </Col>
                                    {propertyConfig.description &&
                                        <Col flex="auto" style={{ paddingLeft: '8px' }}>
                                            <FontAwesomeIcon
                                                style={{
                                                    cursor: 'pointer',
                                                    color: tipHovered ? 'var(--primary-5)' : 'inherit',
                                                }}
                                                icon={faInfoCircle} />
                                        </Col>
                                    }
                                </Row>
                            </Tooltip>
                        </Col>
                        {onRemove &&
                            <Col flex="none">
                                <FontAwesomeIcon
                                    className="property-filter-item__remove"
                                    onClick={() => onRemove(propertyConfig.acronym!)}
                                    style={{ cursor: 'pointer', width: '18px', fontSize: '108%' }}
                                    icon={faTimes} />
                            </Col>
                        }
                    </Row>
                    <Row style={{ width: 'calc(100% + 16px)', marginLeft: '-8px' }}>{children}</Row>
                </Col>
            </Row>
        </div>
    );
}