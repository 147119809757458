import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Table, TableColumnsType } from 'antd';
import { SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import { faEye, faEyeSlash, faTrash, faExpand } from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToLine, faArrowsFromLine } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appStore } from 'stores/AppStore';
import { addSpacesBeforeCapitals, capitalizeFirstLetter, time } from 'utils/Utils';
import { trackColumns } from 'settings/ColumnConfigs';
import moment from 'moment';
import TooltipIcon from 'msg-ui-react/dist/components/tooltip-icon/TooltipIcon';
import { ExtendedTrackDto } from '../../models/ExtendedTrackDto';


const columnNamesTracks: { [key: string]: string } = {
    "hasReport": "Report",
    "hasSeawebMatch": "Seaweb"
}

interface Props {
    trackDtos: ExtendedTrackDto[] | undefined;
    mmsi?: number;
    onClick?: (record: ITrackDtoRow) => void;
}

export interface ITrackDtoRow extends ExtendedTrackDto {
    highlight: boolean;
    eyeClosed: boolean;
}

export const Tracks: React.FC<Props> = ({
    trackDtos,
    mmsi,
    onClick
}) => {
    const { uiStore } = appStore;
    const [isCollapsed, setIsCollapsed] = useState(true);
    const columns: TableColumnsType<ITrackDtoRow> | undefined = [];

    if (!trackDtos || trackDtos.length === 0 || mmsi === undefined)
        return <></>;

    columns.push({
        title: <>
            <FontAwesomeIcon
                onClick={() => uiStore.toggleMmsi()}
                icon={uiStore.hiddenTrackIds.length < trackDtos.length ? faEye : faEyeSlash}
                style={{ cursor: 'pointer', marginRight: uiStore.hiddenTrackIds?.length > 0 ? '4px' : undefined }} />
            {uiStore.hiddenTrackIds?.length > 0 &&
                <TooltipIcon
                    onClick={() => setIsCollapsed(!isCollapsed)}
                    title={(isCollapsed ? 'Show' : 'Hide') + ' non-visible tracks (on map) in table'}
                    icon={isCollapsed ? faArrowsFromLine : faArrowsToLine}
                    style={{ cursor: 'pointer' }}
                    placement='leftBottom' />
            }
        </>,
            
        dataIndex: 'eyeClosed',
        key: 'eyeClosed',
        render: (value: any, record: ITrackDtoRow) => {
            return <>
                <FontAwesomeIcon onClick={() => uiStore.toggleHiddenTrackId(record.id)} icon={record.eyeClosed ? faEyeSlash : faEye} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                <FontAwesomeIcon onClick={() => uiStore.showTrackExtent(record.id)} icon={faExpand} style={{ marginLeft: '8px', cursor: 'pointer' }} />
                <div className="track-rect" style={{ marginLeft: '8px', marginRight: '8px', backgroundColor: record.color}} />
                {record.isRemoved &&
                    <FontAwesomeIcon icon={faTrash} />
                }
            </>
        }
    })

    columns.push(...trackColumns.filter(key => key !== "id").map(key => {
        return {
            title: columnNamesTracks[key] ?? capitalizeFirstLetter(addSpacesBeforeCapitals(key)),
            dataIndex: key,
            key: key,
            defaultSortOrder: (key === 'priority' ? 'ascend' : null) as SortOrder,
            sorter: (a: ITrackDtoRow, b: ITrackDtoRow) => {
                const aValue = (a as any)[key];
                const bValue = (b as any)[key];
                if (typeof aValue === 'number' && typeof bValue === 'number') {
                    return aValue - bValue;
                }
                else if (moment.isMoment(aValue) && moment.isMoment(bValue)) {
                    return (aValue as moment.Moment).valueOf() - (bValue as moment.Moment).valueOf(); 
                }

                return aValue ? aValue.localeCompare(b) : 0;
            }
        }
    }));

    const dataSource: ITrackDtoRow[] = trackDtos.filter(ti => isCollapsed === false || uiStore.hiddenTrackIds.indexOf(ti.id) === -1).map((ti) => {
        return {
            ...ti,
            key: ti.id,
            highlight: uiStore.selectedTrack === ti.id,
            eyeClosed: uiStore.hiddenTrackIds.indexOf(ti.id) > -1,
            start: time(ti.start),
            end: time(ti.end),
            length: (ti.lengthBow ?? 0) + (ti.lengthStern ?? 0),
            width: (ti.widthPort ?? 0) + (ti.widthStarboard ?? 0)
        }
    }) as any;

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onRow={(record: ITrackDtoRow, rowIndex: number | undefined) => {
                return {
                    onClick: () => {
                        if (onClick)
                            onClick(record);
                    }
                };
            }}
            rowClassName={(record: ITrackDtoRow) => record.highlight ? 'highlight-row' : ''}
            showSorterTooltip={false}
            size="small"
            className="track-table"
        >
        </Table>
    );
};

export default observer(Tracks);