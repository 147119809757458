import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Tabs } from 'antd';

import { appStore } from 'stores/AppStore';
import { Tab } from 'stores/UiStore';
import Statistics from 'ui/Statistics';
import Vessels from 'ui/Vessels';
import Debug from 'ui/Debug';
import Override from 'ui/Override';


const onChange = (key: string) => {
 //   appStore.uiStore.setSelectedMmsi(undefined);
    appStore.uiStore.setCurrentTab(Number.parseInt(key));
};

const App: React.FC = () => {
    const { statisticsStore, vesselStore, uiStore, overrideStore } = appStore;
    const [isLoaded, setIsLoaded] = useState(false);

    React.useEffect(() => {
        appStore.load().then(() => setIsLoaded(true));
    }, []);

    if (!isLoaded)
        return null;

    return (< Tabs
        defaultActiveKey={uiStore.currentTab.toString()}
        activeKey={uiStore.currentTab.toString()}
        onChange={onChange}
        items={
            [
                {
                    label: `Map`,
                    key: Tab.Debug.toString(),
                    children: <Debug />,
                },
                {
                    label: `Vessels`,
                    key: Tab.Vessels.toString(),
                    children: <Vessels vesselInfos={vesselStore.vesselInfos} />,
                },
                {
                    label: `Override`,
                    key: Tab.Override.toString(),
                    children: <Override firewallPriorityOverrides={overrideStore.firewallPriorityOverrides} />,
                },
                {
                    label: `Statistics`,
                    key: Tab.Home.toString(),
                    children: <Statistics firewallInfos={statisticsStore.firewallInfos} />,
                },
            ]}
        style={{ overflow: 'hidden' }}
    />)
}

export default observer(App); 