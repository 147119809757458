export type AnyOption = "Any";
export type EqualOption = "Equal to";
export type BetweenOptions = "Between" | "Not between";
export type GreateSmallerOptions =
    | "Greater than"
    | "Smaller than"
    | "Greater than or equal"
    | "Smaller than or equal";
export type ComparingOptions =
    | AnyOption
    | EqualOption
    | BetweenOptions
    | GreateSmallerOptions;

export class ComparingOptionStrings {
    static Any: ComparingOptions = "Any";
    static Equal: ComparingOptions = "Equal to";
    static Between: ComparingOptions = "Between";
    static NotBetween: ComparingOptions = "Not between";
    static Greater: ComparingOptions = "Greater than";
    static Smaller: ComparingOptions = "Smaller than";
    static GreaterEqual: ComparingOptions = "Greater than or equal";
    static SmallerEqual: ComparingOptions = "Smaller than or equal";

    static All: ComparingOptions[] = [
        ComparingOptionStrings.Any,
        ComparingOptionStrings.Equal,
        ComparingOptionStrings.Between,
        ComparingOptionStrings.NotBetween,
        ComparingOptionStrings.Greater,
        ComparingOptionStrings.Smaller,
        ComparingOptionStrings.GreaterEqual,
        ComparingOptionStrings.SmallerEqual,
    ];
}


export interface FCFilterEvent {
    acronym?: string;
    value?: any;
    applyImmediately?: boolean;
}