import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Layout } from 'antd';
import { Content } from "antd/lib/layout/layout";

import MapDefaultControls from "msg-ui-map-react/dist/controls/MapDefaultControls";
import MapSideBar from "msg-ui-map-react/dist/components/MapSideBar";
import ResponsiveMap from "msg-ui-map-react/dist/components/ResponsiveMap";
import ResizableSider from 'msg-ui-react/dist/components/resizable-sider/ResizableSider';

import { appStore } from 'stores/AppStore';
import { useMap } from 'hooks/map';
import { container } from "map/map";
import { time } from 'utils/Utils';
import { ExtendedPointDto } from 'models/ExtendedPointDto';

import DebugRightPanel from './debug/DebugRightPanel';
import { trackColors } from 'settings/Settings';
import MapLowerPanel from './debug/MapLowerPanel';
import BusySpinner from 'msg-ui-react/dist/components/busy-spinner/BusySpinner';

interface Props { }

export const Debug: React.FC<Props> = ({
}) => {
    const [resizableWidth, setResizableWidth] = useState<number>(250);

    const { uiStore, mapStore, debugStore } = appStore;

    const map = useMap(true);

    useEffect(() => {
        if (map && appStore.websettings) {
            const { chartLayer, createChartLayer, graticuleLayer, createGraticuleLayer } = mapStore

            if (!chartLayer) {
                createChartLayer({ chartLayer: appStore.websettings.chartLayer, osmLayer: appStore.websettings.osmLayer, satelliteLayer: appStore.websettings.satelliteLayer });
            }
            else if (!graticuleLayer) {
                createGraticuleLayer();
            }

            if (chartLayer && graticuleLayer) {
                map.addLayer(chartLayer);
                map.addLayer(graticuleLayer);
            }
        }
    }, [map, appStore.websettings, mapStore?.chartLayer, mapStore?.graticuleLayer]);

    return (<div className='debug'>

        <Layout style={{ flexDirection: 'row-reverse', height: 'calc(100vh - 46px)'}}>
            <ResizableSider
                minWidth={320}
                defaultWidth={320}
                collapsedWidth={0}
                collapsed={!debugStore.loadedMmsi}
                style={{ backgroundColor: 'white' }}
                className="resizable-sider"
                callback={(width: number) => setResizableWidth(width)}
            >
                {debugStore.loadedMmsi &&
                    <DebugRightPanel />
                }
            </ResizableSider>
            <Content>
                <div style={{
                    height: "100%",
                    position: "relative",
                    background: "rgb(69, 110, 119)",
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <ResponsiveMap map={map} mapId={container} >
                        <MapSideBar
                            mobile={false}
                            map={map}
                            chart={mapStore.chart}
                            graticule={mapStore.graticule}
                            units={mapStore.units}
                        />
                        <MapDefaultControls
                            map={map} mobile={false}
                            attribution={false}
                            mouse={false}
                        />
                    </ResponsiveMap>

                    <div id="popup" className="ol-popup">
                        <div id="popup-content"><Popup hoveredTrackId={uiStore.hoveredTrackId} currentHoverPoint={uiStore.currentHoverPoint} /></div>
                    </div>

                    <MapLowerPanel />

                </div>
            </Content>
        </Layout>
        {debugStore.isLoading && < BusySpinner /> } 
    </div>)
};
export default observer(Debug);

interface PropsPopup {
    hoveredTrackId: string | undefined;
    currentHoverPoint: ExtendedPointDto | undefined;
}

const Popup: React.FC<PropsPopup> = ({ hoveredTrackId, currentHoverPoint }) => {
    if (!hoveredTrackId || !currentHoverPoint)
        return null;

    const trackDto = appStore.debugStore.trackDtos?.find(track => track && track.id && track.id === hoveredTrackId);

    return (
        <div>
            <div style={{
                marginRight: '8px',
                display: 'inline-block',
                backgroundColor: trackColors[(trackDto?.priority ?? 0) % trackColors.length],
                width: '22px',
                height: '12px'
            }}></div>
            <span>P: {trackDto?.priority} T: {time(currentHoverPoint.time)}</span>
        </div>
    )
}