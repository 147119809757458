import { Switch } from 'antd';
import { observer } from 'mobx-react';
import { BooleanPropertyConfig } from 'msg-properties-client-web/dist/generated';
import { BooleanFilter } from 'msg-spaces-client-web/dist/v2.0';
import React, { useEffect, useState } from 'react';

import { BooleanFilterBuilder } from './builders/BooleanFilterBuilder';
import { FilterComponent } from './FilterComponent';


interface Props {
    description?: string;
    filterBuilder: BooleanFilterBuilder;
    onChange: (booleanFilter: BooleanFilterBuilder) => void;
    onRemove?: ((acronym: string) => void);
}

export const BooleanFilterComponent: React.FC<Props> = ({ description, filterBuilder, onChange, onRemove }) => {
    const onChangeSwitch = (checked: boolean) => {
        filterBuilder.setValue(checked);
        onChange(filterBuilder);
    }

    return (
        <FilterComponent propertyConfig={filterBuilder.propertyConfig} onRemove={onRemove} description={description}>
            <Switch checked={filterBuilder.value} defaultChecked={filterBuilder.value}
                checkedChildren="Yes" unCheckedChildren="No" onChange={onChangeSwitch} size="default" style={{ marginLeft: '8px', marginTop: '4px' }} />
        </FilterComponent>
    );
}

export default observer(BooleanFilterComponent);