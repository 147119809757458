import { makeObservable, observable } from "mobx";

import { MapStore } from "msg-ui-map-react/dist/stores/MapStore";
import { SentryClient } from "msg-sentry-client-web";
import { ClientConfig } from "msg-swagger";

import { TokenClient, WebClientSettings, WebClientSettingsClient } from "swagger/generated";

import { BaseStore } from "./BaseStore";
import { DebugStore } from "./DebugStore";
import { StatisticsStore } from "./StatisticsStore";
import { UiStore } from "./UiStore";
import { VesselStore } from "./VesselStore";
import { OverrideStore } from "./OverrideStore";


export const clientConfig = new ClientConfig('1.0', () => SentryClient.getUser().access_token);

export class AppStore {

    mapStore: MapStore;

    debugStore: DebugStore = new DebugStore(this);
    overrideStore: OverrideStore = new OverrideStore(this);
    statisticsStore: StatisticsStore = new StatisticsStore(this);
    uiStore: UiStore = new UiStore(this);
    vesselStore: VesselStore = new VesselStore(this);

    websettings: WebClientSettings

    constructor() {
        makeObservable(this, {
            statisticsStore: observable,
            uiStore: observable
        });

        const chartTokenClient = new TokenClient(clientConfig);
        this.mapStore = new MapStore(() => chartTokenClient.get())
    }

    async load() {

        this.websettings = await new WebClientSettingsClient(clientConfig).get();        

        await this.debugStore.load(clientConfig);
        await this.overrideStore.load(clientConfig);
        await this.statisticsStore.load(clientConfig);
        await this.uiStore.load(clientConfig);
        await this.vesselStore.load(clientConfig);

        // Then setup reactions
        for (let key of Object.keys(this)) {
            const o = (this as any)[key];
            if (o instanceof BaseStore)
                o.init();
        }
    }
}

export const appStore = new AppStore();

