import { SorterResult } from 'antd/lib/table/interface';
import { computed, makeObservable, observable } from 'mobx';
import { Coordinate } from 'ol/coordinate';
import MultiPoint from 'ol/geom/MultiPoint.js';

import { ClientConfig } from 'msg-swagger';

import { DataClient, DebugFilter, FileParameter, PointDto} from 'swagger/generated';

import { ExtendedPointDto } from 'models/ExtendedPointDto';
import { ExtendedTrackDto } from 'models/ExtendedTrackDto';
import * as Geo from 'utils/Geo';
import { AppStore } from './AppStore';
import { BaseStore } from './BaseStore';
import { ShowTrackOptions, ShowTrackOptionsOutliers } from './UiStore';

export class DebugStore extends BaseStore {

    public dataClient: DataClient;

    trackDtos: ExtendedTrackDto[] | undefined;
    isLoading: boolean = false;
    loadedMmsi: number | undefined;
    isFileUpload: boolean | undefined;

    get visibleTracks() {
        return this.trackDtos?.filter(trackDto => this.root.uiStore.hiddenTrackIds.indexOf(trackDto.id) === -1);
    }

    get visiblePoints() {
        return this.visibleTracks?.flatMap(trackDto => trackDto.extendedPoints) ?? [];
    }

    get visibleFilteredPoints() {
        return this.visiblePoints
            ?.filter(p =>
             (this.root.uiStore.filterStartTime?.isBefore(p.time) ?? true)
             && (this.root.uiStore.filterEndTime?.isAfter(p.time) ?? true)
             && this.filterPoints(p, this.root.uiStore.showOptionPoints)
             && this.filterOutliers(p, this.root.uiStore.showOptionOutliers))
            ?.sort(this.pointSorter)
            ?? []
    }

    logMessages: string[];

    sorterResult: SorterResult<PointDto> = { field: "Time", order: 'ascend' };
    setSorterResult(sorterResult: SorterResult<PointDto>) {
        this.sorterResult = sorterResult;
    }

    constructor(appStore: AppStore) {
        super(appStore);

        makeObservable(this, {
            trackDtos: observable,
            visibleTracks: computed,
            visiblePoints: computed,
            visibleFilteredPoints: computed,
            logMessages: observable,
            sorterResult: observable,
            setSorterResult: observable,
            loadedMmsi: observable,
            isLoading: observable,
            isFileUpload: observable
        });
    }

    init(): void {
        this.dataClient.logMessages().then(logMessages => this.logMessages = logMessages);
    }

    async load(config: ClientConfig) {
        this.dataClient = new DataClient(config);
    }

    async loadTracks(mmsi: number) {
        if (this.isLoading
            || this.root.uiStore.dataStartTime == null
            || this.root.uiStore.dataEndTime == null) {
            return
        }

        try {
            this.isLoading = true;
            this.isFileUpload = false;
            this.trackDtos = undefined;

            const data = await this.dataClient.tracks(mmsi, undefined, undefined, new DebugFilter({ from: this.root.uiStore.dataStartTime, to: this.root.uiStore.dataEndTime }));

            this.loadedMmsi = mmsi
            this.trackDtos = data.results.map(dto => new ExtendedTrackDto(dto));
            this.root.uiStore.setHiddenTrackIds(this.trackDtos.filter(dto => dto.isRemoved).map(dto => dto.id));
            this.root.uiStore.selectedTrack = undefined;
            this.root.uiStore.selectedPoint = undefined;

            // Set correct start end end time when retured results are outside range
            if (data.startTime !== undefined && data.startTime < this.root.uiStore.dataStartTime) {
                this.root.uiStore.setDataStartTime(data.startTime);
            }
            if (data.endTime !== undefined && data.endTime > this.root.uiStore.dataEndTime) {
                this.root.uiStore.setDataEndTime(data.endTime);
            }
        } catch (e) {

        }
        finally {
            this.isLoading = false;
        }
    }

    async uploadFile(mmsi: number | undefined, file: FileParameter) {
        if (this.isLoading) {
            return
        }

        try {
            this.isLoading = true;
            this.isFileUpload = true;

            let upload = await this.dataClient.upload(undefined, undefined, mmsi, file, undefined, undefined, undefined, undefined);

            this.loadedMmsi = upload.mmsi;
            this.trackDtos = upload.results.map(dto => new ExtendedTrackDto(dto));
            this.root.uiStore.setHiddenTrackIds(this.trackDtos.filter(dto => dto.isRemoved).map(dto => dto.id));
            this.root.uiStore.selectedTrack = undefined;
            this.root.uiStore.selectedPoint = undefined;

            if (this.root.uiStore.dataStartTime == null && upload.startTime !== undefined) {
                this.root.uiStore.setDataStartTime(upload.startTime);
            }

            if (this.root.uiStore.dataEndTime == null && upload.endTime !== undefined) {
                this.root.uiStore.setDataEndTime(upload.endTime);
            }

        } catch (e) {

        }
        finally {
            this.isLoading = false;
        }
    }


    async download(mmsi: number, type: string) {
        if (this.isLoading
            || this.root.uiStore.dataStartTime == null
            || this.root.uiStore.dataEndTime == null) {
            return
        }

        try {
            this.isLoading = true;

            switch (type) {
                case "FWR":
                    return await this.dataClient.firewallReportFileDownload(mmsi, new DebugFilter({ from: this.root.uiStore.dataStartTime, to: this.root.uiStore.dataEndTime }));
                case "ORE":
                    return await this.dataClient.oreFileDownload(mmsi, new DebugFilter({ from: this.root.uiStore.dataStartTime, to: this.root.uiStore.dataEndTime }));
                default:
                    throw new Error("Not implemented download type");
            }

        } catch (e) {

        }
        finally {
            this.isLoading = false;
        }
    }

    getPointExtent(point: ExtendedPointDto) {
        if (!point)
            return undefined;

        let coordinates: Coordinate[] | undefined;

        const matchTo = point?.log?.[0];
        const matchToNumber = matchTo !== undefined ? Number.parseInt(matchTo) : undefined;
        const matchToPoint = matchToNumber !== undefined ?
            this.visibleFilteredPoints.find(p => p.index === matchToNumber) : undefined;

        if (matchToPoint) {
            coordinates = [
                Geo.degToMet([point.lon, point.lat]),
                Geo.degToMet([matchToPoint.lon, matchToPoint.lat])
            ]
        } else {
            coordinates = [
                Geo.degToMet([point.lon, point.lat]),
            ]
        }

        return new MultiPoint(coordinates).getExtent();
    }

    getCurrentExtent() {
        return new MultiPoint(this.visibleFilteredPoints.map(p => Geo.degToMet([p.lon, p.lat]))).getExtent();
    }

    private pointSorter = (a: ExtendedPointDto, b: ExtendedPointDto) => {
        if (!a || !b)
            return 0;
        
        if (this.sorterResult?.field === 'Time') {
            if (this.sorterResult.order?.toLowerCase() === 'descend')
                return a.time.isBefore(b.time) ? 1 : -1;
            else
                return a.time.isAfter(b.time) ? 1 : -1;
        } else if (this.sorterResult?.field === 'Index') {
            if (this.sorterResult.order?.toLowerCase() === 'descend')
                return a.index < b.index ? 1 : -1;
            else
                return a.index >= b.index ? 1 : -1;
        }

        return 0;
    }

    private filterPoints = (point: PointDto, showTrackOption?: ShowTrackOptions) => {
        if (point.isOutlier || point.isLongRange)
            return true;

        switch (showTrackOption) {
            case ShowTrackOptions.ShowA:
                return point.isTrackB === false;
            case ShowTrackOptions.ShowB:
                return point.isTrackB;
            case ShowTrackOptions.ShowBoth:
                return true;
            default:
                return true;
        }
    }

    private filterOutliers = (point: PointDto, showTrackOptionOutliers?: ShowTrackOptionsOutliers) => {
        if (point.isOutlier === false && point.isLongRange === false)
            return true;

        switch (showTrackOptionOutliers) {
            case ShowTrackOptionsOutliers.No:
                return false;
            case ShowTrackOptionsOutliers.Yes:
                return true;
            default:
                return true;
        }
    }
}