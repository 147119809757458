import { makeObservable, observable } from 'mobx';

import { ClientConfig } from 'msg-swagger';

import { OverrideClient, FirewallPriorityOverride } from 'swagger/generated';

import { AppStore } from './AppStore';
import { BaseStore } from './BaseStore';

export class OverrideStore extends BaseStore {

    overrideClient: OverrideClient | undefined;
    firewallPriorityOverrides: FirewallPriorityOverride[] | undefined;

    loading: boolean = false;

    constructor(appStore: AppStore) {
        super(appStore);
        makeObservable(this, {
            firewallPriorityOverrides: observable,
            loading: observable
        });
    }

    init() { }

    async load(config?: ClientConfig) {
        if (!this.overrideClient && config)
            this.overrideClient = new OverrideClient(config);

        this.loading = true;

        try {
            this.firewallPriorityOverrides = await this.overrideClient!.getALL();
        } catch (e) {

        }
        finally{
            this.loading = false;
        }
    }

    async addOrUpdate(firewallPriorityOverride: FirewallPriorityOverride) {
        return await this.overrideClient?.addOrUpdate(firewallPriorityOverride).then(() => this.load());
    }

    async delete(firewallPriorityOverride: FirewallPriorityOverride) {
        return await this.overrideClient?.remove(firewallPriorityOverride.mmsi).then(() => this.load());
    }
}