import { TrackDto } from "swagger/generated";
import { trackColors } from "../settings/Settings";
import { ExtendedPointDto } from "./ExtendedPointDto";

export class ExtendedTrackDto extends TrackDto {
    constructor(trackDto: TrackDto) {
        super(trackDto);

        this.color = trackColors[(trackDto?.priority ?? 0) % trackColors.length];
        this.pointCount = trackDto.points?.length ?? 0;
    }

    color: string;
    pointCount: number;

    get extendedPoints() {
        return this.points?.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority)) ?? [];
    }

    get pointsA() {
        const points = this.points?.filter(p => p.isTrackB === false && p.isOutlier === false && p.isLongRange === false) ?? [];
        return points.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority));
    }

    get pointsB() {
        const points = this.points?.filter(p => p.isTrackB === true && p.isOutlier === false && p.isLongRange === false) ?? [];
        return points.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority));
    }

    get outliersA() {
        const points = this.points?.filter(p => p.isTrackB === false && p.isOutlier === true && p.isLongRange !== true) ?? [];
        return points.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority));
    }

    get outliersB() {
        const points = this.points?.filter(p => p.isTrackB === true && p.isOutlier === true &&  p.isLongRange !== true) ?? [];
        return points.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority));
    }
    get longrangeA() {
        const points = this.points?.filter(p => p.isTrackB === false && p.isLongRange === true) ?? [];
        return points.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority));
    }

    get longrangeB() {
        const points = this.points?.filter(p => p.isTrackB === true && p.isLongRange === true) ?? [];
        return points.map(p => new ExtendedPointDto(p, this.id, this.color, this.priority));
    }
}