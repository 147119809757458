import { Circle as CircleStyle, Stroke, Style, Text, Fill } from 'ol/style';
import StrokeStyle from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import * as ol_color from 'ol/color';
import { ColorLike } from 'ol/colorlike';
import { ShipIcon } from './ShipIcon';
import { TrackLineString, TrackMultiPoint } from 'map/features/Track';

export enum pointStyleColor {
    normal = 1,
    outlier = 2,
    longrange = 3
}
export function lineStyle(geometry: TrackLineString, styles: Style[], scale: number, color: ol_color.Color | ColorLike, highlight?: boolean, highlightIndex?: number, highlightMatchIndex?: number) {

    styles.push(new Style({
        geometry,
        stroke: new Stroke({
            width: 2,
            color: color
        }),
    }));

    pointStyle(new TrackMultiPoint(geometry.getCoordinates(), geometry.points), styles, scale, pointStyleColor.normal, highlightIndex, highlightMatchIndex);
}

function GetColorValue(color: pointStyleColor = pointStyleColor.normal) {
    switch (color) {
        case pointStyleColor.outlier:
            return [255, 102, 102, 0.5];
        case pointStyleColor.longrange:
            return [0, 208, 255, 0.5];
        case pointStyleColor.normal:
        default:
            return [102, 255, 102, 0.5];
    }
}

export function pointStyle(geometry: TrackMultiPoint, styles: Style[], scale: number, color: pointStyleColor = pointStyleColor.normal, highlightIndex?: number, highlightMatchIndex?: number) {
    const iconStyle = new ShipIcon();
    const pointDtos = geometry.points;

    geometry.getCoordinates().forEach((coord, index) => {
        const cog = pointDtos[index].cog !== undefined ? pointDtos[index].cog! : null;
        styles.push(new Style({
            zIndex: 10,
            geometry: new Point(coord),
            image: iconStyle.getIcon(scale, cog, GetColorValue(color)),
        }));

        if (highlightIndex != undefined && highlightIndex === geometry.points[index].index) {
            const circleHighlight = [
                    new Style({
                        image: new CircleStyle({
                            radius: 20 * window.devicePixelRatio,
                            stroke: new StrokeStyle({ color: '#FFFF00', width: 4 }),
                        }),
                        geometry: new Point(coord),
                        zIndex: 20
                    })
                ];
            circleHighlight.forEach(s => s.getImage().setScale(1 / window.devicePixelRatio));
            
            styles.push(...circleHighlight);
        }

        if (highlightMatchIndex != undefined && highlightMatchIndex === geometry.points[index].index) {
            const circleHighlight = [
                new Style({
                    image: new CircleStyle({
                        radius: 18 * window.devicePixelRatio,
                        stroke: new StrokeStyle({ color: '#FFFFFF', width: 1}),
                    }),
                    geometry: new Point(coord),
                    zIndex: 20
                })
            ];
            circleHighlight.forEach(s => s.getImage().setScale(1 / window.devicePixelRatio));

            styles.push(...circleHighlight);
        }

        // just for debugging, normally mark should always be false
        if (geometry.points[index].mark) {
            const circleMark = [
                new Style({
                    image: new CircleStyle({
                        radius: 20 * window.devicePixelRatio,
                        stroke: new StrokeStyle({ color: '#FF00FF', width: 1 }),
                    }),
                    geometry: new Point(coord),
                    zIndex: 20
                })
            ];
            circleMark.forEach(s => s.getImage().setScale(1 / window.devicePixelRatio));

            styles.push(...circleMark);
        }
    });

    return styles;
}