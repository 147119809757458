import { action, makeObservable, observable, reaction } from 'mobx';

import { BasePropertyConfig, PropertyType } from 'msg-properties-client-web/dist/generated';
import { BaseFilter, BooleanFilter } from "msg-spaces-client-web/dist/v2.0";
import { ClientConfig } from 'msg-swagger';

import { FilteredDataRequest, StatisticsClient, VesselInfoDto } from 'swagger/generated';

import { isValidMmsiPropertyConfig } from 'settings/PropertyConfigs';
import { BooleanFilterBuilder } from 'ui/filter/builders/BooleanFilterBuilder';
import { FilterBuilder } from 'ui/filter/builders/FilterBuilder';
import { FilterBuilderFactory } from 'ui/filter/builders/FilterBuilderFactory';

import { capitalizeFirstLetter } from 'utils/Utils';
import { AppStore } from './AppStore';
import { BaseStore } from './BaseStore';


export interface IPaginationConfiguration {
    take: number;
    skip: number;
    sort?: string;
    order?: string;
}

const validMmsiFilter = new BooleanFilter();
validMmsiFilter.init({ acronym: 'isValidMmsi', value: true, type: PropertyType.Boolean });

export const defaultFilterBuilders = [new BooleanFilterBuilder(isValidMmsiPropertyConfig, validMmsiFilter)];

export class VesselStore extends BaseStore {

    statisticsClient: StatisticsClient | undefined;

    vesselInfos: VesselInfoDto[] | undefined;
    vesselCount: number;

    filters: BaseFilter[] = [];
    filterBuilders: FilterBuilder<BaseFilter>[] = defaultFilterBuilders;

    paginationConfiguration: IPaginationConfiguration = { take: 100, skip: 0, sort: 'TotalTracks', order: 'descend' }

    currentPage: number = 1;
    setCurrentPage(currentPage: number) {
        this.currentPage = currentPage;
    }

    loading: boolean = false;

    constructor(appStore: AppStore) {
        super(appStore);
        makeObservable(this, {
            vesselInfos: observable,
            vesselCount: observable,
            filters: observable,
            filterBuilders: observable,
            paginationConfiguration: observable,
            currentPage: observable,
            setCurrentPage: action.bound,
            loading: observable
        });
    }

    init() {      
        reaction(() => this.filterBuilders, () => {
            this.filters = this.filterBuilders.map(fb => fb.toFilter()).filter(f => !!f) as BaseFilter[];
        }, { fireImmediately: true })

        reaction(() => this.filters, () => this.load());
    }

    async load(config?: ClientConfig) {
        if (!config && !this.statisticsClient)
            return;

        if (!this.statisticsClient)
            this.statisticsClient = new StatisticsClient(config!);

        this.loading = true;

        const f = new FilteredDataRequest();
        f.skip = this.paginationConfiguration.skip;
        f.take = this.paginationConfiguration.take;
        f.sort = this.paginationConfiguration.sort;
        f.sortDirection = this.paginationConfiguration.order;
        f.filters = this.filters;

        // don't send valid mmsi filter if not set to true (result would be only invalid mmsis not all mmsis)
        const index = f.filters.findIndex(f => f.acronym?.toLowerCase() === "isvalidmmsi")
        if (index > -1 && !(f.filters[index] as BooleanFilter).value)
            f.filters.splice(index, 1);

        // needed for backend because acronym should be equal to property name
        f.filters.forEach(filter => filter.acronym = capitalizeFirstLetter(filter.acronym ?? ""))

        try {
            const pageResult = await this.statisticsClient!.vessels(f);

            this.vesselInfos = pageResult.result;
            this.vesselCount = pageResult.totalItemCount;
        } catch (e: any) {

        } finally {
            this.loading = false;
        }
    }

    addFilterBuilder(propertyConfig: BasePropertyConfig, filter?: BaseFilter) {
        const filterBuilder = FilterBuilderFactory.create(propertyConfig, filter);        
        if (filterBuilder === undefined)
            return;

        this.filterBuilders = this.filterBuilders.concat([filterBuilder]);            
    }
}