
import LayerGroup from 'ol/layer/Group';
import { TracksVectorLayer } from './TracksVectorLayer';

/*
 * Group that manages all ship related layers. ship image, ship features, past tracks 
 */

export class TracksLayer extends LayerGroup {

    constructor() {

        super({ zIndex: 10 });

        var layers = this.getLayers();

        this.tracksVectorLayer = new TracksVectorLayer();
        layers.push(this.tracksVectorLayer);
    }

    readonly tracksVectorLayer: TracksVectorLayer;
}