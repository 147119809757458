import { Col, Row, Input } from 'antd';
import React, { useEffect, useState } from 'react';

import { StringFilterBuilder } from 'ui/filter/builders/StringFilterBuilder';
import { FilterComponent } from './FilterComponent';


interface Props {
    description?: string;
    filterBuilder: StringFilterBuilder;
    onChange: (stringFilter: StringFilterBuilder) => void;
    onRemove?: ((acronym: string) => void);
}

export const StringFilterComponent: React.FC<Props> = ({ description, filterBuilder, onChange, onRemove }) => {
    const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event: React.ChangeEvent<HTMLInputElement>) => {
        filterBuilder.setValue(event.target.value);
        onChange(filterBuilder);
    }

    return (
        <FilterComponent propertyConfig={filterBuilder.propertyConfig} onRemove={onRemove} description={description}>
            <Row>
                <Col span={24}>
                    <Input autoComplete="off" value={filterBuilder.value} onChange={handleChange} style={{ width: '100%' }} />
                </Col>
            </Row>
        </FilterComponent>
    );
}