import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faInfo, faOption } from '@fortawesome/pro-solid-svg-icons';
import { Descriptions, Space, Table, TableColumnsType, Tabs } from 'antd';
import { observer } from 'mobx-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TooltipIcon from 'msg-ui-react/dist/components/tooltip-icon/TooltipIcon';

import { PointDto } from 'swagger/generated';

import { SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import { ExtendedPointDto } from 'models/ExtendedPointDto';
import moment from 'moment';
import { appStore } from 'stores/AppStore';
import { BottomPanelTab, DebugTab } from 'stores/UiStore';
import * as Geo from 'utils/Geo';
import { capitalizeFirstLetter, time } from 'utils/Utils';


export interface IPointRow extends ExtendedPointDto {
    key: string;
    highlighted: boolean;
}

export const DebugRightPanel: React.FC<{}> = () => {
    const { uiStore } = appStore;

    const onChange = (key: string) => {
        appStore.uiStore.setCurrentDebugTab(Number.parseInt(key));
    };

    const items = [
        {
            label: 'Points',
            key: DebugTab.Points.toString(),
            children: <PointsTab />
        },
        {
            label: 'Track',
            key: DebugTab.Summary.toString(),
            children: <TrackTab />
        }
    ];

    return (< Tabs
        defaultActiveKey={uiStore.currentDebugTab.toString()}
        activeKey={uiStore.currentDebugTab.toString()}
        onChange={onChange}
        items={items}
        style={{ overflow: 'hidden' }}
        size="small"
    />)
}

const PointsTab = observer(({}) => {
    const { debugStore, uiStore } = appStore;

    const pointDtos = debugStore.visibleFilteredPoints; 

    const columns: TableColumnsType<IPointRow> = [];

    columns.push({
        title: '',
        dataIndex: 'icon',
        key: 'icon',
        width: '24px',
        onCell: (record: IPointRow, rowIndex) => {
            return {
                onMouseEnter: event => event.stopPropagation(),
            };
        },
    });

    columns.push({
        title: 'Index',
        dataIndex: 'index',
        key: 'index',
        sorter: (a: ExtendedPointDto, b: ExtendedPointDto, sortOrder?: SortOrder) => {
            if (typeof a.index === 'number' && typeof b.index === 'number') {
                return a.index < b.index ? -1 : 1;
            }

            return 0;
        }
    });

    columns.push({
        title: 'Time',
        dataIndex: 'time',
        key: 'time',
        render: (text, record: PointDto, index) => time(record.time),
        sorter: (a: ExtendedPointDto, b: ExtendedPointDto, sortOrder?: SortOrder) => {
            if (moment.isMoment(a.time) && moment.isMoment(b.time)) {
                return a.time.isBefore(b.time) ? -1 : 1; //  < (b as any)[key]) ? -1 : 1;
            }

            return 0;
        },
        defaultSortOrder: 'ascend'
    });

    columns.push({
        title: '',
        dataIndex: 'alternative',
        key: 'alternative'
    });

    columns.push({
        title: '',
        dataIndex: 'outlier',
        key: 'outlier'
    })

    const datasource: IPointRow[] = pointDtos.map((p, i) => {
        return {
            ...p,
            key: i,
            index: p.index,
            icon: <div style={{
                marginLeft: '8px',
                display: 'inline-block',
                backgroundColor: p.trackColor,
                width: '22px',
                height: '12px'
            }}></div>,
            alternative: p.isTrackB ? <FontAwesomeIcon icon={faOption} /> : undefined,
            outlier: p.isLongRange
                ? <FontAwesomeIcon icon={faExclamationCircle} color="blue" />
                : (p.isOutlier ? <FontAwesomeIcon icon={faExclamationCircle} color="red" /> : undefined),
            highlighted: p.index === uiStore.selectedPoint?.index 
        } as any;
    });

    const handleChange = (tablePaginationConfig: TablePaginationConfig, filters: any, sorter: any) => {
        debugStore.setSorterResult({ field: capitalizeFirstLetter(sorter.field), order: sorter.order });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 84px)' }}>
            <div style={{ display: 'flex', minHeight: 0, flexGrow: 1, overflow: 'auto' }}>
                <div style={{ padding: '0px 12px 0px 12px', width: '100%' }}>
                    <Table
                        columns={columns}
                        dataSource={datasource}
                        pagination={{ size: "small", pageSize: 100}}
                        size="small"
                        onRow={(record: IPointRow, _rowIndex: number | undefined) => {
                            return {
                                onClick: event => {
                                    uiStore.olMap.getView().setCenter(Geo.degToMet([record.lon, record.lat]))
                                    uiStore.setSelectedPoint(record);
                                    uiStore.setCurrentMapLowerPanelTab(BottomPanelTab.PointDetails);
                                },
                            };
                        }}
                        rowClassName={(record, _index) => record.highlighted ? 'highlighted-row highlight-bottom-border highlight-top-border ' : ''}
                        className="point-table"
                        style={{ maxWidth: '296px' }}
                        onChange={handleChange}
                    >
                    </Table>
                </div>
            </div>
        </div>
    )
})

const TrackTab = observer(() => {
    const { uiStore, debugStore } = appStore;

    const selectedTrack = uiStore.selectedTrack ?? (debugStore.trackDtos && debugStore.trackDtos.length > 0 ? debugStore.trackDtos?.[0].id : "");

    const currentTrackDto = (debugStore.trackDtos ?? []).find(ti => ti && ti.id && ti.id === selectedTrack);

    return (
        <Space direction="vertical" size="middle" style={{ padding: '12px 24px', backgroundColor: 'white', width: '100%', height: '100%' }} className="debug-right-panel-summary" >
            {currentTrackDto &&
                <>
                    <div style={{ textAlign: 'center', fontWeight: 600 }}>
                        {(currentTrackDto?.name ?? "Unnamed") + ' ' + (currentTrackDto?.priority !== undefined ? `(${currentTrackDto?.priority})` : '')
                        }
                    </div>
                    <Descriptions size="small" bordered column={1} title="Static Info">
                        <Descriptions.Item label={
                            <div>
                                <TooltipIcon title='The number of times the static data of the track has changed (confirmed matches)' icon={faInfo} />
                                <span style={{ marginLeft: '8px' }}>Static changes</span>
                            </div>
                        }>
                        {currentTrackDto.staticChanges}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <div>
                                <TooltipIcon title='The Number of times static data has been matched to the track (not necessarily confirmed)' icon={faInfo} />
                                <span style={{ marginLeft: '8px' }}>Static matched</span>
                            </div>
                        }>
                            {currentTrackDto.staticMatched}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <div>
                                <TooltipIcon title='Has the static data of the track a match to seaweb?' icon={faInfo} />
                                <span style={{ marginLeft: '8px' }}>Has seaweb match</span>
                            </div>
                        }>
                            {currentTrackDto.hasSeawebMatch ? 'Yes' : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={
                            <div>
                                <TooltipIcon title='Has the track a confirmed static data report?' icon={faInfo} />
                                <span style={{ marginLeft: '8px' }}>Has report</span>
                            </div>
                        }>
                            {currentTrackDto.hasReport ? 'Yes' : 'No'}
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small" bordered column={1} title="Track Info">
                        <Descriptions.Item label={
                            <div>
                                <TooltipIcon title='The total number of points added to the track' icon={faInfo} />
                                <span style={{ marginLeft: '8px' }}>Point count</span>
                            </div>
                        }>
                            {currentTrackDto.pointCount}
                        </Descriptions.Item>
                    <Descriptions.Item label={
                        <div>
                            <TooltipIcon title='The total number of outliers added to the track' icon={faInfo} />
                            <span style={{ marginLeft: '8px' }}>Outlier count</span>
                        </div>
                    }>
                        {currentTrackDto.outlierCount}
                    </Descriptions.Item>
                </Descriptions>
                </>
            }
        </Space>
    )
})

export default observer(DebugRightPanel);