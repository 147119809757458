import { useEffect, useState } from "react";

import uiMap from 'msg-ui-map-react/dist/Map';

import { Map } from 'map/map';
import { appStore } from 'stores/AppStore';


export const useMap = (
    visible: boolean,
) => {
    const [map, setMap] = useState<uiMap | undefined>(undefined);

    useEffect(() => {
        if (!appStore.uiStore.olMap && visible) {
            appStore.uiStore.olMap = new Map();
            setMap(appStore.uiStore.olMap);
        }
        else if (!visible) {
            setMap(undefined);
        }

        return () => { };
    }, [visible]);


    return map;
}