import React from 'react';
import { observer } from 'mobx-react';

import { PropertyType } from 'msg-properties-client-web/dist/generated';
import { BaseFilter } from 'msg-spaces-client-web/dist/v2.0';

import { filterNameDescriptions } from 'settings/Settings';
import { appStore } from 'stores/AppStore';
import { BooleanFilterBuilder } from 'ui/filter/builders/BooleanFilterBuilder';
import { FilterBuilder } from 'ui/filter/builders/FilterBuilder';
import { IntFilterBuilder } from 'ui/filter/builders/IntFilterBuilder';
import IntFilterComponent from './IntFilterComponent';
import BooleanFilterComponent from './BooleanFilterComponent';
import { StringFilterComponent } from './StringFilterComponent';
import { StringFilterBuilder } from './builders/StringFilterBuilder';


export interface FilterComponentsProps {
    filterBuilders: FilterBuilder<BaseFilter>[];
    defaultFilterBuilderIds?: number[];
}

export const FilterComponents: React.FC<FilterComponentsProps> = ({ filterBuilders, defaultFilterBuilderIds }) => {

    const changeFilter = (filterBuilder: FilterBuilder<BaseFilter>, index: number) => {
        appStore.vesselStore.filterBuilders[index] = filterBuilder;
        appStore.vesselStore.filterBuilders = appStore.vesselStore.filterBuilders.slice();
    }

    const removeFilter = (index: number) => {
        appStore.vesselStore.filterBuilders.splice(index, 1);
        appStore.vesselStore.filterBuilders = appStore.vesselStore.filterBuilders.slice();
    }

    return (
        <div className="property-filter-wrapper" style={{ display: 'flex', flexWrap: 'wrap' }}>
            {filterBuilders.map((filterBuilder, index) => {
                const onRemove = (defaultFilterBuilderIds == undefined || defaultFilterBuilderIds.indexOf(index) == -1) ? (_acr: string) => removeFilter(index) : undefined;
                switch ((filterBuilder.propertyConfig as any).type) {                    
                    case PropertyType.Int:
                        return <IntFilterComponent
                            description={filterNameDescriptions[filterBuilder.acronym!]}
                            filterBuilder={filterBuilder as IntFilterBuilder}
                            onChange={(filterBuilder: IntFilterBuilder) => changeFilter(filterBuilder, index)}
                            onRemove={onRemove }/>;
                    case PropertyType.Boolean:
                        return <BooleanFilterComponent
                            description={filterNameDescriptions[filterBuilder.acronym!]}
                            filterBuilder={filterBuilder as BooleanFilterBuilder}
                            onChange={(filterBuilder: BooleanFilterBuilder) => changeFilter(filterBuilder, index)}
                            onRemove={onRemove} />;
                    case PropertyType.String:
                        return <StringFilterComponent
                            description={filterNameDescriptions[filterBuilder.acronym!]}
                            filterBuilder={filterBuilder as StringFilterBuilder}
                            onChange={(filterBuilder: StringFilterBuilder) => changeFilter(filterBuilder, index)}
                            onRemove={onRemove} />;
                    default:
                        return <div></div>
                }
            })}
        </div>
    );
}

export default observer(FilterComponents);