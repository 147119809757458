import { runInAction } from "mobx";

import TileLayer from "ol/layer/Tile";
import MapBrowserEvent from 'ol/MapBrowserEvent';
import OSM from "ol/source/OSM";
import Overlay from 'ol/Overlay';

import View from 'msg-ui-map-react/dist/View';
import uiMap from "msg-ui-map-react/dist/Map";

import { TracksLayer } from "map/layers/TracksLayer";
import { appStore } from "stores/AppStore";
import { TrackFeature } from "./features/Track";
import { ExtendedPointDto } from "../models/ExtendedPointDto";
import { BottomPanelTab } from "../stores/UiStore";


export const container = 'map-container-' + Math.round(Math.random() * 10000);

export class Map extends uiMap {

    readonly tracksLayer: TracksLayer;
    readonly overlay: Overlay;

    constructor() {
        super({
            view: new View({
                center: [0, 0],
                zoom: 1
            }),
            // target: 'map-container',
            controls: [],
        });

        this.overlay = new Overlay({
            element: document.getElementById('popup') || undefined,
            autoPan: {
                animation: {
                    duration: 250,
                },
            },
        });
        this.addOverlay(this.overlay);

        this.tracksLayer = new TracksLayer();
        this.addLayer(this.tracksLayer);

        this.on('click', (evt: MapBrowserEvent<UIEvent>) => { // TODO: right event type?
            appStore.uiStore.setSelectedPoint(undefined);

            const hit = this.tracksLayer.tracksVectorLayer.hitDetect(evt);
            if (hit instanceof TrackFeature) {
                const trackId = hit.getId() as string;
                appStore.uiStore.setSelectedTrack(trackId);
            } else if (hit instanceof ExtendedPointDto) {
                appStore.uiStore.setSelectedPoint(hit);
                appStore.uiStore.setCurrentMapLowerPanelTab(BottomPanelTab.PointDetails);
            }
        });

        this.on('pointermove', (evt: MapBrowserEvent<UIEvent>) => {
            this.tracksLayer.tracksVectorLayer.mouseMove(evt, true);
        });

        this.on("moveend", () => {
            runInAction(() => {
                const c = this.getView().getCenter();

                if (c !== undefined) {

                    // Sometimes the zoom level can become fractional, (when zoom interaction are interupted)
                    // This can break some layers like ShipImage. Force back to an integer value if this happens.
                    const zoom = this.getView().getZoom();
                    if (zoom !== undefined) {
                        const zoomInt = Math.round(zoom);
                        if (zoomInt !== zoom) {
                            this.getView().setZoom(zoomInt);
                        }

                        appStore.uiStore.setZoomLevel(zoomInt);
                    }
                }
            });
        });
    }
}