/* File is copied from ShipIcon.ts in Msg.Tracker.Web and adjusted slightly (mainly removed stuff) */

import StrokeStyle from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import * as ol_color from 'ol/color';
import { Color } from 'ol/color';

import * as Utils from 'utils/Utils';
import * as Geo from 'utils/Geo';
import { memoize as memoizer } from 'utils/Memoize';
import Icon from 'ol/style/Icon';


export class ShipIcon  {

    constructor(memoize = true) {
        if (memoize) {
            this.getIconInternal = memoizer(this.getIconInternal);
        }
    }

    private readonly width = 24;
    private readonly height = 24;
    private readonly circleRadius = 5;
    private readonly circleSegments = 16;
    public strokeStyle = new StrokeStyle({
        color: [0, 0, 0, 1],
        width: 1.5
    });
    private readonly iconShape = [
        [1, 10.5],
        [6, -9], [5, 1],
        [-6, -9], [0, -6],
        [-1, 10.5], [-5, 1],
        [1, 10.5], [0, 12]
    ];

    getStyle(scale: number, rotation: number | null, color: Color, zIndex: number) : Style {
        if (rotation !== null)
            rotation = Utils.round(rotation, 6);
        if (scale !== null)
            scale = Utils.round(scale, 0.1);

        return new Style({
            image: this.getIcon(scale, rotation, color),
            zIndex
        });
    }

    getIcon(scale: number, rotation: number | null, color: Color) {
        // Round off parameters before calling momoized function
        if (rotation !== null)
            rotation = Utils.round(rotation, 6);
        if (scale !== null)
            scale = Utils.round(scale, 0.1);

        return this.getIconInternal(scale, rotation, color);
    }

    private getIconInternal(scale: number, rotation: number | null, color: Color): Icon {

        const canvas = document.createElement('canvas') as HTMLCanvasElement;

        canvas.width = this.width * window.devicePixelRatio;
        canvas.height = this.height * window.devicePixelRatio;

        const context = canvas.getContext("2d") as CanvasRenderingContext2D;
        context.scale(window.devicePixelRatio, window.devicePixelRatio);

        // Set fill and stroke
        context.fillStyle = ol_color.asString(color);
        context.strokeStyle = ol_color.asString(this.strokeStyle.getColor() as Color);
        context.lineWidth = false ? 1 : + (this.strokeStyle.getWidth() ?? 1);

        // Render as pointy shape or circle depending on whether we have rotational info
        context.beginPath();
        if (false) {
            this.renderAton(context);
        } else if (rotation == null) {
            this.renderNonDirectional(context);
        } else {
            this.renderDirectional(context, scale, rotation);
        }
        context.closePath();
        context.stroke();
        context.fill();

        return new Icon({
            img: canvas,
            imgSize: [canvas.width, canvas.height],
            anchor: [0.5, 0.5],
            scale: 1 / window.devicePixelRatio,
            //rotateWithView: true,
        });
    }

    private renderDirectional(context: CanvasRenderingContext2D, scale: number, rotation: number) {

        const rot_rad = Geo.degToRad(rotation) + Math.PI;

        context.translate(this.width / 2, this.height / 2);
        context.scale(scale, scale);
        context.rotate(rot_rad);

        var points = this.iconShape.concat();

        context.moveTo(points[0][0], points[0][1]);
        points.shift();

        for (let i = 0; i < points.length; i += 2) {
            const p = points[i];
            const a = points[i + 1];
            context.quadraticCurveTo(a[0], a[1], p[0], p[1]);
        }
    }

    private renderNonDirectional(context: CanvasRenderingContext2D) {
        context.moveTo(this.width / 2, this.circleRadius + this.height / 2);
        for (let i = 1; i < this.circleSegments; i++) {
            const a = (i / this.circleSegments) * Math.PI * 2;
            context.lineTo(Math.sin(a) * this.circleRadius + this.width / 2,
                Math.cos(a) * this.circleRadius + this.height / 2);
        }
    }

    private renderAton(context: CanvasRenderingContext2D) {
        context.rect(
            this.width / 2 - this.circleRadius + 0.5,
            this.height / 2 - this.circleRadius + 0.5,
            this.circleRadius * 2,
            this.circleRadius * 2
        );
    }
}
