import React, { useEffect } from 'react';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { Descriptions } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';

import { FirewallInfo } from 'swagger/generated';

import { appStore } from 'stores/AppStore';
import { Tab } from 'stores/UiStore';
import { defaultFilterBuilders } from 'stores/VesselStore';
import { statisticToDictionary } from 'settings/Settings';
import { BaseFilter } from 'msg-spaces-client-web/dist/v2.0';



interface Props {
    firewallInfos: FirewallInfo[] | undefined;
}

export const Statistics: React.FC<Props> = ({
    firewallInfos
}) => {
    const { vesselStore, uiStore } = appStore;

    const setFilters = (firewallInfo: FirewallInfo) => {
        const conditions = statisticToDictionary[firewallInfo.name];        

        uiStore.setCurrentTab(Tab.Vessels);
        vesselStore.setCurrentPage(1);

        runInAction(() => {
            vesselStore.filterBuilders = defaultFilterBuilders;
            
            for (let i = 0; i < conditions?.length; i++) {
                vesselStore.addFilterBuilder(conditions[i].propertyConfig, conditions[i].filter);
            }
        })      
    }

    const getDescriptions = () => {
        let descriptions: any = []; 

        firewallInfos?.forEach(firewallInfo => {
            descriptions = descriptions.concat(firewallInfo.metrics.map(metric => <Descriptions.Item label={
                <div>{firewallInfo.name}
                    {statisticToDictionary[firewallInfo.name] && <FontAwesomeIcon
                        className="property-filter-item__remove"
                        onClick={() => setFilters(firewallInfo)}
                        style={{ cursor: 'pointer', width: '18px', fontSize: '108%', float: 'right' }}
                        icon={faFilter}
                    />}
                </div>
            }>{metric}</Descriptions.Item>));
        });

        return descriptions;
    }

    return (
        <Descriptions bordered column={1} size="small" style={{maxWidth: '1200px'} }>
            {
                getDescriptions()
            }
        </Descriptions>
    );
};

export default observer(Statistics);