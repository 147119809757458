import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Form, Input, InputNumber, Modal, Switch } from 'antd';

import { FirewallPriorityOverride, } from 'swagger/generated';


interface Props {
    firewallPriorityOverride: FirewallPriorityOverride | undefined;
    onOk: (firewallPriorityOverride: FirewallPriorityOverride) => void;
    modalOpen: boolean;
    setModalOpen: (open: boolean) => void;
}

export const OverrideEditCreateModal: React.FC<Props> = ({
    firewallPriorityOverride,
    onOk,
    modalOpen,
    setModalOpen
}) => {

    const [currentOverride, setCurrentOverride] = useState<FirewallPriorityOverride>(firewallPriorityOverride ?? new FirewallPriorityOverride());

    React.useEffect(() => {
        if (firewallPriorityOverride)
            setCurrentOverride(firewallPriorityOverride);
    }, [firewallPriorityOverride]);

    const [form] = Form.useForm();

    form.setFieldValue('mmsi', currentOverride.mmsi);
    form.setFieldValue('isActive', currentOverride.isActive);
    form.setFieldValue('imo', currentOverride.imoNumber);
    form.setFieldValue('name', currentOverride.name);
    form.setFieldValue('callsign', currentOverride.callSign);
    form.setFieldValue('lengthbow', currentOverride.lengthBow);
    form.setFieldValue('lengthstern', currentOverride.lengthStern);
    form.setFieldValue('widthport', currentOverride.widthPort);
    form.setFieldValue('widthstarboard', currentOverride.widthStarboard);

    return (
        <Modal visible={modalOpen}
            closable={false}
            onOk={() => form.submit()}
            onCancel={() => setModalOpen(false)}>
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                autoComplete="off"
                form={form}
                onFinish={() => onOk(currentOverride)}
            >
                <Form.Item
                    label="Mmsi"
                    name="mmsi"
                    rules={[{ required: true, message: 'Please input mmsi' }]}
                >
                    <InputNumber defaultValue={currentOverride.mmsi} onChange={value => currentOverride.mmsi = value ?? 0} />
                </Form.Item>

                <Form.Item label="Is Active" name="isActive">
                    <Switch defaultChecked={currentOverride.isActive} onChange={value => currentOverride.isActive = value}></Switch>
                </Form.Item>

                <Form.Item
                    label="Imo number"
                    name="imo"
                >
                    <InputNumber defaultValue={currentOverride.imoNumber} onChange={value => currentOverride.imoNumber = value != null ? value : undefined} />
                </Form.Item>

                <Form.Item
                    label="Name"
                    name="name"
                >
                    <Input defaultValue={currentOverride.name} onChange={event => currentOverride.name = event.target.value} />
                </Form.Item>

                <Form.Item
                    label="Call Sign"
                    name="callsign"
                >
                    <Input defaultValue={currentOverride.callSign} onChange={event => currentOverride.callSign = event.target.value} />
                </Form.Item>

                <Form.Item
                    label="Length Bow"
                    name="lengthbow"
                >
                    <InputNumber defaultValue={currentOverride.lengthBow} onChange={value => currentOverride.lengthBow = value != null ? value : undefined} />
                </Form.Item>

                <Form.Item
                    label="Length Stern"
                    name="lengthstern"
                >
                    <InputNumber defaultValue={currentOverride.lengthStern} onChange={value => currentOverride.lengthStern = value != null ? value : undefined} />
                </Form.Item>

                <Form.Item
                    label="Width Port"
                    name="widthport"
                >
                    <InputNumber defaultValue={currentOverride.widthPort} onChange={value => currentOverride.widthPort = value != null ? value : undefined} />
                </Form.Item>

                <Form.Item
                    label="Width Starboard"
                    name="widthstarboard"
                >
                    <InputNumber defaultValue={currentOverride.widthStarboard} onChange={value => currentOverride.widthStarboard = value != null ? value : undefined} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default observer(OverrideEditCreateModal);