import { DateTimePropertyConfig, DoublePropertyConfig, IntPropertyConfig, } from "msg-properties-client-web/dist/generated";
import { IntFilter, DoubleFilter, DateTimeFilter, BaseFilter  } from "msg-spaces-client-web/dist/v2.0";
import { FilterBuilder } from "./FilterBuilder";


export type BoundedFilter = IntFilter | DoubleFilter | DateTimeFilter;
export type BoundedPropertyConfig = IntPropertyConfig | DoublePropertyConfig | DateTimePropertyConfig;

export abstract class BoundedFilterBuilder<T extends BoundedFilter> extends FilterBuilder<T> {
    abstract override propertyConfig: BoundedPropertyConfig;

    override setValue = (value: any) => {
        this.value = value;
    }

    abstract value2: any;
    setValue2 = (value2: any) => {
        this.value2 = value2;
    }

    override isValid() {
        switch (this.selectedComparingOption) {
            case "Smaller than":
            case "Smaller than or equal":
            case "Greater than":
            case "Greater than or equal":
            case "Equal to":
                return this.value != undefined && this.value != null;
            case "Between":
            case "Not between":
                return this.greater(this.value2, this.value);
            default:
                return true;
        }
    }

    fromFilter(filter: BoundedFilter) {
        if (filter.min != undefined && filter.max != undefined) {
            this.value = filter.min;
            this.value2 = filter.max;

            if (filter.between)
                this.selectedComparingOption = "Between";
            else
                this.selectedComparingOption = "Not between";
        }
        else if (filter.min != undefined) {
            this.value = filter.min;
            if (filter.inclusive)
                this.selectedComparingOption = "Greater than or equal";
            else
                this.selectedComparingOption = "Greater than";
        }
        else if (filter.max != undefined) {
            this.value = filter.max;
            if (filter.inclusive)
                this.selectedComparingOption = "Smaller than or equal";
            else
                this.selectedComparingOption = "Smaller than";
        }
        else {
            console.log("Warning in BoundedFilterBuilder.ts: invalid filter: ", filter);
        }
    }

    toFilter(): BoundedFilter | undefined {
        const filter = super.toFilter() as BoundedFilter;

        switch (this.selectedComparingOption) {
            case "Smaller than":
                filter.max = this.value;
                break;
            case "Smaller than or equal":
                filter.max = this.value;
                filter.inclusive = true;
                break;
            case "Greater than":
                filter.min = this.value;
                break;
            case "Greater than or equal":
                filter.min = this.value;
                filter.inclusive = true;
                break;
            case "Between":
                filter.min = this.value;
                filter.max = this.value2;
                filter.between = true;
                break;
            case "Not between":
                filter.min = this.value;
                filter.max = this.value2;
                filter.between = false;
                break;
            default:
                break;
        }

        return filter;
    }

    abstract greater: (value1: any, value2: any) => boolean | undefined;
    abstract greaterOrEqually: (value1: any, value2: any) => boolean | undefined;
}