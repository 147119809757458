import { PointDto } from "swagger/generated";


export class ExtendedPointDto extends PointDto {

    constructor(pointDto: PointDto, trackId: string, trackColor: string, trackPriority: number) {
        super(pointDto);
        this.trackId = trackId;
        this.trackColor = trackColor;
        this.trackPriority = trackPriority;
        ;
    }

    trackId: string;
    trackColor: string;
    trackPriority: number;
}