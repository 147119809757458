import { AppStore } from './AppStore';

/**
 * Base class for state subtrees 
 */
export abstract class BaseStore
{

    // Constructor gets the root state passed, do not override
    constructor(protected root: AppStore) { }

    // Override and set up any reaction in the init method
    abstract init(): void;
}