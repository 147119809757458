import { BooleanPropertyConfig } from "msg-properties-client-web/dist/generated";
import { BooleanFilter } from "msg-spaces-client-web/dist/v2.0";
import { FilterBuilder } from "./FilterBuilder";


export class BooleanFilterBuilder extends FilterBuilder<BooleanFilter> {
    constructor(booleanPropertyConfig: BooleanPropertyConfig, booleanFilter?: BooleanFilter) {
        super(BooleanFilter);

        this.propertyConfig = booleanPropertyConfig;
        if (booleanFilter)
            this.fromFilter(booleanFilter);
    }

    override propertyConfig: BooleanPropertyConfig;

    override selectedComparingOption = undefined;

    override value: boolean | undefined;
    override setValue(value: boolean) {
        this.value = value;
    }

    override isValid() {
        return true;
    }

    override fromFilter(filter: BooleanFilter) {
        this.value = filter?.value;
    }

    override toFilter() {
        const filter = super.toFilter() as BooleanFilter;
        filter.value = this.value;
        return filter;
    }
}