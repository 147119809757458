import { IntPropertyConfig } from "msg-properties-client-web/dist/generated";
import { IntFilter } from "msg-spaces-client-web/dist/v2.0";
import { BetweenOptions, ComparingOptions, ComparingOptionStrings, EqualOption, GreateSmallerOptions } from "ui/filter/models/models";
import { BoundedFilterBuilder } from "./BoundedFilterBuilder";


export class IntFilterBuilder extends BoundedFilterBuilder<IntFilter> {
    override propertyConfig = new IntPropertyConfig();

    override selectedComparingOption = ComparingOptionStrings.Equal;

    override value: number | undefined;
    override value2: number | undefined;

    constructor(intPropertyConfig: IntPropertyConfig, intFilter?: IntFilter) {
        super(IntFilter);

        this.propertyConfig = intPropertyConfig;
        if (intFilter)
            this.fromFilter(intFilter);
    }

    override fromFilter(filter: IntFilter) {
        super.fromFilter(filter);

        if (filter.values && filter.values.length == 1) {
            this.selectedComparingOption = ComparingOptionStrings.Equal
            this.value = filter.values[0];
        }
        else {
            console.log("Warning in IntFilterBuilder: invalid filter: ", filter);
        }
    }

    override toFilter() {
        if (!this.isValid())
            return undefined;

        const filter = super.toFilter() as IntFilter;

        switch (this.selectedComparingOption) {
            case "Equal to":
                filter.values = [this.value!];
                break;
            default:
                break;
        }

        return filter;
    }

    override greater = (value1: number | undefined, value2: number | undefined) => {
        if (value1 == undefined || value2 == undefined)
            return undefined;

        return value1 > value2;
    }

    override greaterOrEqually = (value1: number | undefined, value2: number | undefined) => {
        if (value1 == undefined || value2 == undefined)
            return undefined;

        return value1 >= value2;
    }
}