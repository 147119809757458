import moment from "moment";
import * as Geo from "./Geo";

export function capitalizeFirstLetter(string: string) {
    if (!string || string === "") {
        return undefined;
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function addSpacesBeforeCapitals(string: string) {
    return string.replace(/([A-Z][a-z])/g, " $1").trim();
}

export function time(time: moment.Moment | undefined, hideTimeZone?: boolean) {
    if (!time)
        return undefined;

    const sameYear = time.year() === moment.utc().year();
    let format = sameYear ? "DD-MMM HH:mm:ss" : "DD-MMM-YYYY HH:mm:ss";
    if (!hideTimeZone) {
        format += " z";
    }
    return time.format(format);
}

export function round(value: number | null, size: number) {
    if (value == null) return 0;
    const s = 1 / size;
    return Math.round(value * s) / s;
}

export function interpolateNumber<T extends (number | null) | number>(v1: T, v2: T, f: number): T | null {
    if (v1 == null || v2 == null) return null as T;
    const n1 = v1 as number, n2 = v2 as number;
    return (n1 + (n2 - n1) * f) as T;
}

export function interpolateAngle(v1: number | null, v2: number | null, f: number) {
    if (v1 == null || v2 == null) return null;
    const diff = Geo.angle180To180(v2 - v1);
    return Geo.angle0To360(v1 + diff * f);
}


export function getUrlParameter(sParam: string): string | null {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        const sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0].toLowerCase() === sParam.toLowerCase()) {
            return sParameterName[1] === undefined ? "" : sParameterName[1];
        }
    }
    return null;
};


/**
 * Asyncronous sleep function
 * 
 * @param ms milliseconds to sleep
 */
export async function sleep(ms: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

/**
 * Get keys of an object of a specific type
 */
export type FilterProps<T, Prop> = ({ [P in keyof T]: T[P] extends Prop ? P : never })[keyof T];

/**
 * Get keys of an object not of a specific type
 */
export type ExcludeProps<T, Prop> = ({ [P in keyof T]: T[P] extends Prop ? never : P })[keyof T];


/**
 * Get a functions argument types 
 */
export type ArgumentTypes<T extends (...a: any[]) => any> = T extends (...a: infer A) => any ? A : [];


/**
 * Get react bootstrap component props
 */
export type BootstrapProps<P, E = HTMLDivElement> = P & Omit<React.HTMLAttributes<E>, keyof P>;