let counter = 0;
export function memoize<T extends Function>(originalMethod: T, hashFunction?: (...args: any[]) => any) {
    const identifier = ++counter;

    if (!hashFunction) hashFunction = simpleSerializer;

    return function (this: any, ...args: any[]) {
        const propValName = `__memoized_value_${identifier}`;
        const propMapName = `__memoized_map_${identifier}`;

        let returnedValue: any;

        if (hashFunction || args.length > 0) {

            // Get or create map
            if (!this.hasOwnProperty(propMapName)) {
                Object.defineProperty(this, propMapName, {
                    configurable: false,
                    enumerable: false,
                    writable: false,
                    value: new Map<any, any>()
                });
            }
            let myMap: Map<any, any> = this[propMapName];

            let hashKey: any;

            if (hashFunction) {
                hashKey = hashFunction.apply(this, args);
            } else {
                hashKey = args[0];
            }

            if (myMap.has(hashKey)) {
                returnedValue = myMap.get(hashKey);
            } else {
                returnedValue = originalMethod.apply(this, args);
                myMap.set(hashKey, returnedValue);
            }

        } else {

            if (this.hasOwnProperty(propValName)) {
                returnedValue = this[propValName];
            } else {
                returnedValue = originalMethod.apply(this, args);
                Object.defineProperty(this, propValName, {
                    configurable: false,
                    enumerable: false,
                    writable: false,
                    value: returnedValue
                });
            }
        }

        return returnedValue
    } as any as T;
}

function simpleSerializer(...args: any[]) {
    var context = [];
    for (var i = args.length - 1; i >= 0; --i) {
        context.push(typeof args[i], args[i]);
    }
    return context.join('\x0B');
};
