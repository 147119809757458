import { StringPropertyConfig } from "msg-properties-client-web/dist/generated";
import { PropertyType, StringFilter } from "msg-spaces-client-web/dist/v2.0";
import { FilterBuilder } from "./FilterBuilder";


export class StringFilterBuilder extends FilterBuilder<StringFilter> {
    constructor(stringPropertyConfig: StringPropertyConfig, stringFilter?: StringFilter) {
        super(StringFilter);

        this.propertyConfig = stringPropertyConfig;
        if (stringFilter)
            this.fromFilter(stringFilter);
    }

    override propertyConfig = new StringPropertyConfig();

    override selectedComparingOption = undefined;

    override value: string | undefined;
    override setValue(value: string | undefined) {
        this.value = value;
    }    

    override isValid() {
        return this.value != undefined && this.value != "";
    }

    override fromFilter(filter: StringFilter) {
        if (filter.values && filter.values.length == 1)
            this.value = filter.values[0];
    }

    override toFilter() {
        if (!this.isValid())
            return undefined;

        const filter = super.toFilter() as StringFilter;
        if (filter)
            filter.values = [this.value!];
        
        return filter;
    }
}