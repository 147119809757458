
import {BooleanPropertyConfig, IntPropertyConfig, PropertyType, StringPropertyConfig } from "msg-properties-client-web/dist/generated";


export const mmsiPropertyConfig = new IntPropertyConfig({
    acronym: 'mmsi',
    name: 'mmsi',
    minValue: 0,
    maxValue: undefined,
    type: PropertyType.Int,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const descriptionPropertyConfig = new StringPropertyConfig({
    acronym: 'Description',
    name: 'Description',
    type: PropertyType.String,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const largeTrackPropertyConfig = new IntPropertyConfig({
    acronym: 'LargeTracks',
    name: 'LargeTracks',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const TrackSameCountPropertyConfig = new IntPropertyConfig({
    acronym: 'SameCount',
    name: 'Same',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const TrackUniquePropertyConfig = new IntPropertyConfig({
    acronym: 'UniqueCount',
    name: 'Unique',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const TrackQualityPropertyConfig = new IntPropertyConfig({
    acronym: 'Quality',
    name: 'Quality',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const TrackOverlapPropertyConfig = new IntPropertyConfig({
    acronym: 'Overlap',
    name: 'Overlap',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const tracksWithStaticPropertyConfig = new IntPropertyConfig({
    acronym: 'TracksWithStatic',
    name: 'TracksWithStatic',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const staticChangesPropertyConfig = new IntPropertyConfig({
    acronym: 'StaticChanges',
    name: 'StaticChanges',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const staticReceivedPropertyConfig = new IntPropertyConfig({
    acronym: 'StaticReceived',
    name: 'StaticReceived',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const staticMatchedPropertyConfig = new IntPropertyConfig({
    acronym: 'StaticMatched',
    name: 'StaticMatched',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const totalTracksPropertyConfig = new IntPropertyConfig({
    acronym: 'TotalTracks',
    name: 'TotalTracks',
    type: PropertyType.Int,
    minValue: 1,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const firstTimeSeenPropertyConfig = new IntPropertyConfig({
    acronym: 'FirstTimeSeen',
    name: 'FirstTimeSeen',
    type: PropertyType.Int,
    minValue: 0,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const maxPointCountPropertyConfig = new IntPropertyConfig({
    acronym: 'MaxPointCount',
    name: 'MaxPointCount',
    type: PropertyType.Int,
    minValue: 1,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const totalPointCountPropertyConfig = new IntPropertyConfig({
    acronym: 'TotalPointCount',
    name: 'TotalPointCount',
    type: PropertyType.Int,
    minValue: 1,
    maxValue: undefined,
    resolution: 0,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const withSeawebPropertyConfig = new BooleanPropertyConfig({
    acronym: 'withSeaweb',
    name: 'withSeaweb',
    type: PropertyType.Boolean,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const isValidMmsiPropertyConfig = new BooleanPropertyConfig({
    acronym: 'isValidMmsi',
    name: 'isValidMmsi',
    type: PropertyType.Boolean,
    isHidden: false,
    isReadonly: false,
    useInFilters: false
});

export const propertyConfigs = [
    mmsiPropertyConfig,
    descriptionPropertyConfig,
    largeTrackPropertyConfig,
    TrackSameCountPropertyConfig,
    TrackUniquePropertyConfig,
    TrackQualityPropertyConfig,
    TrackOverlapPropertyConfig,
    tracksWithStaticPropertyConfig,
    staticChangesPropertyConfig,
    staticReceivedPropertyConfig,
    staticMatchedPropertyConfig,
    totalTracksPropertyConfig,
    firstTimeSeenPropertyConfig,
    maxPointCountPropertyConfig,
    totalPointCountPropertyConfig,
    withSeawebPropertyConfig,
    isValidMmsiPropertyConfig
];