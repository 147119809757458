import { makeObservable, observable, reaction, toJS } from 'mobx';

import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';
import VectorImageLayer from 'ol/layer/VectorImage';
import MapBrowserEvent from 'ol/MapBrowserEvent';

import * as Geo from 'utils/Geo';

import { TrackFeature } from '../features/Track';
import { appStore } from 'stores/AppStore';


export class TracksVectorLayer extends VectorImageLayer<VectorSource> {

    constructor() {
        super({ zIndex: 20 });

        makeObservable(this, {
            loaded: observable
        });

        this.source = new VectorSource();
        this.setSource(this.source);

        reaction(() => appStore.uiStore.hiddenTrackIds, () => this.loadTracks());

        // trackDtos are fetched when attached mmsis in debugger change
        reaction(() => toJS(appStore.debugStore.trackDtos), () => this.loadTracks());
    }

    source: VectorSource;

    loaded: boolean = false;

    getExtentTrack(trackId: string) {
        return this.source.getFeatureById(trackId)?.getGeometry()?.getExtent();
    }

    hitDetect(evt: MapBrowserEvent<UIEvent>) {
        if (!this.getVisible() || evt.map.getView().getResolution()! > this.getMaxResolution()) {
            return null;
        } else {
            return this.closestPoint(evt) || this.closestLine(evt) || this.closestPoly(evt);
        }
    }

    mouseMove(evt: MapBrowserEvent<UIEvent>, enabled: boolean): boolean {
        if (enabled) {
            let feature: TrackFeature | undefined = this.source.getClosestFeatureToCoordinate(evt.coordinate) as TrackFeature;
            if (!this.featureWithinRange(feature, evt, 25))
                feature = undefined;

            const hit = feature?.hitDetect(evt);
            if (hit) {
                appStore.uiStore.setCurrentHoverPoint(hit);
                appStore.uiStore.setHoveredTrackId(feature!.getId() as string);
                appStore.uiStore.olMap.overlay.setPosition(evt.coordinate);
                return true;
            }
            else {
                appStore.uiStore.setCurrentHoverPoint(undefined);
                appStore.uiStore.setHoveredTrackId(undefined);
                appStore.uiStore.olMap.overlay.setPosition(undefined);
            }
        }

        return false;
    }

    private featureWithinRange(feature: TrackFeature, evt: MapBrowserEvent<UIEvent>, maxDist: number) {
        const g = feature?.getGeometry();
        if (!feature || !g)
            return null;

        const coord = g.getClosestPoint(evt.coordinate);
        const pixel = evt.map.getPixelFromCoordinate(coord);
        const distance = Geo.distance(evt.pixel, pixel);
        return distance <= maxDist ? feature : null;
    }

    private loadTracks() {
        if (appStore.debugStore.trackDtos === undefined) {
            this.loaded = false;
            return
        }

        this.source.clear();

        const trackFeatures: TrackFeature[] = []
        appStore.debugStore.trackDtos
            ?.filter(track => track && track.id && appStore.uiStore.hiddenTrackIds.indexOf(track.id) === -1)
            ?.forEach((track, index) => {
                const trackFeature = new TrackFeature(track);
                trackFeatures.push(trackFeature);
            })

        console.log("new track features: ", trackFeatures);

        this.source.addFeatures(trackFeatures);
        this.loaded = true;
    }

    private closestPoint(evt: MapBrowserEvent<UIEvent>) {
        const features = this.source.getFeatures() as TrackFeature[];
        for (let feature of features) {
            const hit = feature.hitDetect(evt);
            if (hit) {
                return hit;
            }
        }

        return undefined;
    }

    private closestLine(evt: MapBrowserEvent<UIEvent>) {
        return this.closestFiltered(evt, (f) => {
            const g = f?.getGeometry();
            return !!g && g.getType().indexOf("LineString") >= 0
        }, 15);
    }

    private closestPoly(evt: MapBrowserEvent<UIEvent>) {
        const atCoord = this.source.getFeaturesAtCoordinate(evt.coordinate);
        if (atCoord.length === 1) {
            return atCoord[0] as TrackFeature;
        } else if (atCoord.length > 1) {
            return this.closestFiltered(evt, f => atCoord.indexOf(f) >= 0, Number.MAX_VALUE);
        } else {
            return this.closestFiltered(evt, f => {
                const g = f?.getGeometry();
                return !!g && g.getType() !== "Point";
            }, 15);
        }
    }

    private closestFiltered(evt: MapBrowserEvent<UIEvent>, filter: (f: Feature) => boolean, maxDist: number): TrackFeature | null {
        const feature = (this.source.getClosestFeatureToCoordinate(evt.coordinate, filter as any));
        const geo = feature?.getGeometry()
        if (!feature || !geo) return null;
        const coord = geo.getClosestPoint(evt.coordinate);
        const pixel = evt.map.getPixelFromCoordinate(coord);
        const distance = Geo.distance(evt.pixel, pixel);
        return distance <= maxDist ? (feature as TrackFeature) : null;
    }
}