import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Space, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { FirewallPriorityOverride, IFirewallPriorityOverride } from 'swagger/generated';

import { appStore } from 'stores/AppStore';
import { addSpacesBeforeCapitals, capitalizeFirstLetter } from 'utils/Utils';
import { SortOrder } from 'antd/lib/table/interface';
import OverrideEditCreateModal from './override/OverrideEditCreateModal';

interface Props {
    firewallPriorityOverrides: FirewallPriorityOverride[] | undefined;
}

export const Override: React.FC<Props> = ({
    firewallPriorityOverrides
}) => {
    const { overrideStore } = appStore;
    const [modalOpen, setModalOpen] = useState(false);
    const [currentOverride, setCurrentOverride] = useState(new FirewallPriorityOverride());

    const dataSource: IFirewallPriorityOverride[] = firewallPriorityOverrides?.map((fpo, i) => {
        return {
            ...fpo,
            key: i,
            isActive: fpo.isActive
        }
    }) as any;

    // : (key: string) => TableColumnsType<IFirewallPriorityOverrideRow> 
    const getColumn= (key: string) => {
        return {
            dataIndex: key,
            key: key,
            title: capitalizeFirstLetter(addSpacesBeforeCapitals(key)),
            render: key === 'isActive' ? (value: any) => value ? 'Yes' : 'No' : undefined,
            sorter: (a: IFirewallPriorityOverride, b: IFirewallPriorityOverride, sortOrder?: SortOrder) => {
                if (typeof (a as any)[key] === 'number' && typeof (b as any)[key] === 'number') {
                    return ((a as any)[key] < (b as any)[key]) ? -1 : 1;
                }

                return 0;
            }
        }
    }

    const columns = [getColumn('mmsi'), getColumn('name'), getColumn('isActive'), getColumn('imoNumber'), getColumn('callSign'), getColumn('lengthBow'), getColumn('lengthStern'), getColumn('widthPort'), getColumn('widthStarboard')];

    columns.push({
        dataIndex: 'icons',
        key: 'icons',
        title: '',
        render: (text: string, record: FirewallPriorityOverride, index: number) => {
            return (
                <>
                    <FontAwesomeIcon icon={faEdit} onClick={() => { setCurrentOverride(record); setModalOpen(true); }} style={{ cursor: 'pointer' }} />
                    <FontAwesomeIcon icon={faTrash} onClick={() => { overrideStore.delete(record); }} style={{ marginLeft: '10px', cursor: 'pointer' }} />
                </>
            )
        }
    } as any)

    const onOk = (firewallPriorityOverride: FirewallPriorityOverride) => {
        overrideStore.addOrUpdate(firewallPriorityOverride);
        setModalOpen(false);
    }

    return (
        <div className='override'>
            <OverrideEditCreateModal
                firewallPriorityOverride={currentOverride}
                onOk={(firewallPriorityOverride) => onOk(firewallPriorityOverride)}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen} />

            <Space direction="vertical">
                <Button onClick={() => setModalOpen(true)}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>

                <Table
                    columns={columns}
                    dataSource={dataSource}
                    pagination={{ size: "small", pageSize: 100 }}
                    loading={overrideStore.loading}
                    showSorterTooltip={false}
                    size="small"
                    style={{ height: '90vh', width: '100%' }}
                    scroll={{ y: 'calc(90vh - 120px)' }}
                >
                </Table> 
            </Space>
          
         </div>
    );
};

export default observer(Override);