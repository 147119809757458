import React from 'react';
import { observer } from 'mobx-react';
import { Table, TableColumnsType, Tooltip } from 'antd';
import { SortOrder, TablePaginationConfig } from 'antd/lib/table/interface';
import { faFilter as faFilterFilled } from '@fortawesome/pro-solid-svg-icons';
import { faFilter as faFilterEmpty, faGlobe } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IVesselInfoDto, VesselInfoDto } from 'swagger/generated';

import { appStore } from 'stores/AppStore';
import { Tab } from 'stores/UiStore';
import { addSpacesBeforeCapitals, capitalizeFirstLetter } from 'utils/Utils';
import { columnNamesVessels } from 'settings/Settings';
import { propertyConfigs } from 'settings/PropertyConfigs';

import FilterComponents from './filter/FilterComponents';

interface Props {
    vesselInfos: VesselInfoDto[] | undefined;
}

interface IVesselInfoRow extends IVesselInfoDto {
}

export const Vessels: React.FC<Props> = ({
    vesselInfos
}) => {
    const { uiStore, vesselStore, debugStore } = appStore;
    let columns: TableColumnsType<IVesselInfoRow> = [];

    columns.push(
        {
            title: '',
            children: [{
                render: (value: any, record: IVesselInfoRow, index: number) => {
                    const icons = [];
                    icons.push(<FontAwesomeIcon style={{ cursor: 'pointer' }} icon={faGlobe} title="Show in map" onClick={() => {
                            uiStore.setDataTimeDefaults(true);
                            debugStore.loadTracks(record.mmsi);
                            uiStore.setSelectedTrack(undefined);
                            uiStore.setCurrentTab(Tab.Debug);
                    }} />)

                    return (<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                        {icons}
                    </div>
                    )
                },
                defaultSortOrder: 'ascend'
            }],
        });

    const getColumn = (key: string, customTitle?: string | JSX.Element) => {
        let title: string | JSX.Element;

        if (customTitle)
            title = customTitle;
        else
            title = columnNamesVessels[key] ?? capitalizeFirstLetter(addSpacesBeforeCapitals(key));

        return {
            title: getTitle(key, title),
            dataIndex: key,
            key: key,
            onCell: getOnCell(),
            sorter: getSorter(key)
        }
    };

    const getTitle = (key: string, title: string | JSX.Element) => {
        return <div onClick={(e) => { e.stopPropagation(); }} style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{title}</div>
            <FontAwesomeIcon
                icon={vesselStore.filterBuilders.findIndex(fb => fb.acronym?.toLowerCase() === key.toLowerCase()) > -1 ? faFilterFilled : faFilterEmpty}
                onClick={() => addFilterBuilder(key)}
                style={{ alignSelf: 'center' }} />
        </div>
    }

    const getOnCell = () => {
        return (record: IVesselInfoRow) => {
            return {
                onClick: (_event: any) => console.log("gj "),
            };
        }
    }

    const getSorter = (key: string) => {
        return (a: IVesselInfoRow, b: IVesselInfoRow, sortOrder?: SortOrder) => {
            if (typeof (a as any)[key] === 'number' && typeof (b as any)[key] === 'number') {
                return ((a as any)[key] < (b as any)[key]) ? -1 : 1;
            }

            return 0;
        }
    }

    columns = columns.concat([
        {
            title: 'Vessel',
            children: [getColumn('mmsi'), getColumn('description', 'Name')]
        },
        {
            title: 'Tracks',
            children: [
                
                // Not using getColumn here to get default sort to work
                {
                    title: getTitle('totalTracks', <Tooltip title='The total number of tracks.' placement='bottom'>Total</Tooltip>),
                    dataIndex: 'totalTracks',
                    key: 'totalTracks',
                    onCell: getOnCell(),
                    sorter: getSorter('totalTracks'),
                    defaultSortOrder: 'descend',
                },
                getColumn('largeTracks',
                    <Tooltip title='The number of large tracks. A large track has at least 100 points (including outliers).'
                        placement='bottom'>Large</Tooltip>),
                getColumn('tracksWithStatic',
                    <Tooltip title='The number of tracks with a confirmed static data report.'
                        placement='bottom'>With Static</Tooltip>),
                getColumn('sameCount',
                    <Tooltip title='The (max) number of tracks with the same static data report.' placement='bottom'>Same</Tooltip>),
                getColumn('uniqueCount',
                    <Tooltip title='The number of unique static data report.' placement='bottom'>Unique</Tooltip>),
                getColumn('concurrency',
                    <Tooltip title='The number of concurrent(active) tracks.' placement='bottom'>Concurrent</Tooltip>),
                getColumn('quality',
                    <Tooltip title='The quality (% valid data points) of the track with highest priority' placement='bottom'>Quality [%]</Tooltip>),
                getColumn('overlap',
                    <Tooltip title='The max time (Minutes) overlap of tracks with static data report.' placement='bottom'>Overlap [m]</Tooltip>),
            ]
        },
        {
            title: 'Static',
            children:
                [
                getColumn('staticChanges',
                    <Tooltip title='The number of times the static data of the tracks have changed (confirmed matches).'
                        placement='bottom'>Changes</Tooltip>
                ), getColumn('staticMatched',
                    <Tooltip title='The number of times static data has been matched to the tracks (not necessarily confirmed).'
                        placement='bottom'>Matched</Tooltip>)]
        },
        {
            title: 'Point Count',
            children: [getColumn('maxPointCount',
                <Tooltip title='The number of points of the largest track.' placement='bottom'>Max</Tooltip>),
                getColumn('totalPointCount',
                    <Tooltip title='The total number of points added to the tracks (including outliers).' placement='bottom'>Total</Tooltip>)]
        }
    ])

    const dataSource: IVesselInfoRow[] = vesselInfos as any;

    const addFilterBuilder = (acr: string) => {
        vesselStore.addFilterBuilder(propertyConfigs.find(config => config.acronym?.toLowerCase() === acr.toLowerCase())!); // !
    }

    const handleChange = (tablePaginationConfig: TablePaginationConfig, filters: any, sorter: any) => {
        const skip = (tablePaginationConfig.current ?? 0) * (tablePaginationConfig.pageSize ?? 0) - (tablePaginationConfig.pageSize ?? 0);
        const take = tablePaginationConfig.pageSize;
        const sort = capitalizeFirstLetter(sorter.field);
        vesselStore.paginationConfiguration = { skip: skip, take: take ?? 100, sort: sort, order: sorter.order }

        vesselStore.load();
    };

    const handleChangePagination = (page: number, pageSize: number) => {
        vesselStore.setCurrentPage(page);
        vesselStore.paginationConfiguration.skip = (page - 1) * pageSize;
        vesselStore.paginationConfiguration.take = pageSize;
        vesselStore.load();
    }

    return (
        <div className='vessels'>
            <FilterComponents filterBuilders={vesselStore.filterBuilders} defaultFilterBuilderIds={[0]} /> 

            <Table
                bordered={true}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                    size: "small",
                    pageSize: 100,
                    total: vesselStore.vesselCount,
                    onChange: handleChangePagination,
                    current: vesselStore.currentPage
                }}
                onChange={handleChange}
                loading={vesselStore.loading}
                showSorterTooltip={false}
                size="small"
                className="vessel-table"
                scroll={{ y: 'calc(100vh - 300px)' }}
            >
            </Table>
        </div>
    );
};

export default observer(Vessels);