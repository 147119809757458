import { BasePropertyConfig, IntPropertyConfig, IntSetPropertyConfig, PropertyType, StringPropertyConfig } from "msg-properties-client-web/dist/generated";
import { BooleanFilter, IntFilter, StringFilter } from "msg-spaces-client-web/dist/v2.0";
import { staticMatchedPropertyConfig, totalTracksPropertyConfig, propertyConfigs, withSeawebPropertyConfig } from "./PropertyConfigs";


export const columnNamesVessels: { [key: string]: string } = {}
export const filterNameDescriptions: { [key: string]: string } = {
    'isValidMmsi': 'Valid MMSIs only',
    'isAttached': 'Attached to debugger only'
}

export const trackColorsOrdered = [
    "#000000", "#444444", "#888888", "#CCCCCC",
    "#FFFFFF", "#A4BDFC", "#5484ED", "#7E55FC",
    "#46D6DB", "#51B749", "#00E13C", "#FBD75B",
    "#FFB878", "#FF887C", "#DC2127", "#843C0C"
]

export const trackColors = trackColorsOrdered.map((_value, index) => trackColorsOrdered[(6 + index * 4 + Math.floor(index / 4)) % 16]);

export interface ICondition {
    propertyConfig: BasePropertyConfig;
    filter: IntFilter;
}

export const statisticToDictionary: { [name: string]: ICondition[]; } = {
    '1 track': [{ propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [1], type: PropertyType.Int }) }],
    '1 track with static data match': [
        { propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [1], type: PropertyType.Int }) },
        { propertyConfig: staticMatchedPropertyConfig, filter: IntFilter.fromJS({ acronym: 'StaticMatched', min: 1, inclusive: true, type: PropertyType.Int }) }
    ],
    '2 tracks': [{ propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [2], type: PropertyType.Int }) }],
    '2 tracks and 1 static data match': [
        { propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [2], type: PropertyType.Int }) },
        { propertyConfig: staticMatchedPropertyConfig, filter: IntFilter.fromJS({ acronym: 'StaticMatched', values: [1], type: PropertyType.Int }) }
    ],
    '2 tracks and 2 static data matches': [
        { propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [2], type: PropertyType.Int }) },
        { propertyConfig: staticMatchedPropertyConfig, filter: IntFilter.fromJS({ acronym: 'StaticMatched', values: [2], type: PropertyType.Int }) }
    ],
    '3 tracks': [{ propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [3], type: PropertyType.Int }) }],
    '3 tracks and 1 static data match': [
        { propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [3], type: PropertyType.Int }) },
        { propertyConfig: staticMatchedPropertyConfig, filter: IntFilter.fromJS({ acronym: 'StaticMatched', values: [1], type: PropertyType.Int }) }
    ],
    '3 tracks and 2 static data matches': [
        { propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [3], type: PropertyType.Int }) },
        { propertyConfig: staticMatchedPropertyConfig, filter: IntFilter.fromJS({ acronym: 'StaticMatched', values: [2], type: PropertyType.Int }) }
    ],
    '3 tracks and 3 static data matches': [
        { propertyConfig: totalTracksPropertyConfig, filter: IntFilter.fromJS({ acronym: 'TotalTracks', values: [3], type: PropertyType.Int}) },
        { propertyConfig: staticMatchedPropertyConfig, filter: IntFilter.fromJS({ acronym: 'StaticMatched', values: [3], type: PropertyType.Int }) }
    ],
    // 'With seaweb': [{ propertyConfig: withSeawebPropertyConfig, filter: BooleanFilter.fromJS({value: true}) }],
};